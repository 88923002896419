






















































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class LandingPage extends Vue {
  @Prop({ required: false, type: Boolean, default: false }) autoplay: boolean;
  player = {
    src: "https://static.goldengoose.com/video/upload/q_auto/e_loop:15/v1/Asset/E-Commerce/sneaker_maker_online/GGDB_SM_ONLINE_WAITING_v2.2.mp4",
    type: "video/mp4",
    controls: false,
  };

  mounted() {
    if (process.env.VUE_APP_ENV === "production") this.addCookieLaw();
  }

  addCookieLaw() {
    const cookieUDID = process.env.VUE_APP_COOKIE_UDID;
    const externalScript = document.createElement("script");
    externalScript.setAttribute("src", "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js");
    externalScript.setAttribute("type", "text/javascript");
    externalScript.setAttribute("charset", "UTF-8");
    externalScript.setAttribute("data-domain-script", cookieUDID);
    document.head.appendChild(externalScript);
  }

  enableVideoControls(e) {
    if (e.srcElement.readyState >= 3) this.player.controls = true;
  }
}

declare global {
  interface Window {
    OptanonWrapper: () => void;
  }
}

window.OptanonWrapper = function () {
  console.log("OptanonWrapper empty function has been called");
};
