export async function sleep(milliseconds: number) {
   return new Promise<void>(resolveSleep => setTimeout(() => {
      resolveSleep();
   }, milliseconds))
}

export class Stopwatch {
   startedAt: number;

   constructor() {
      this.start()
   }

   start() {
      this.startedAt = performance.now();
   }

   elapsed() {
      return performance.now() - this.startedAt;
   }

   restart() {
      this.start();
   }

   elapsedMinutes(minutes: number) {
      return this.elapsedSeconds(minutes * 60);
   }

   elapsedSeconds(seconds: number) {
      return this.elapsed() >= seconds * 1000;
   }
}