


















































































































import Vue from "vue";

export default class ExitPage extends Vue {
    visitSite() {
        window.location.href = "https://www.goldengoose.com/";
    }
}
