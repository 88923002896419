















import Vue from "vue";
import BluetoothService from "@/zero/services/bluetooth-service/bluetooth-service";
import { Component, Prop } from "vue-property-decorator";
@Component({})
export default class BluetoothCheck extends Vue {
  @Prop({ default: null, type: String }) experienceMode: "InStore" | "Online" | null;
  showNewBluetoothDevice: boolean = false;
  bluetoothService: BluetoothService;

  constructor() {
    super();
    this.bluetoothService = new BluetoothService();
    this.bluetoothService.$on("new-bluetooth-device", (deviceClass: string) => {
      this.onNewBluetoothDevice.call(this, [deviceClass]);
    });

    this.bluetoothService.$on("bluetooth-device-removed", (deviceClass: string) => {
      // Add desired behavior
    });
  }

  acceptNewDevice() {
    this.$emit("new-device-accepted");
  }

  dismiss() {
    this.showNewBluetoothDevice = false;
  }

  onNewBluetoothDevice(deviceClass: string) {
    console.log("SM page new bluetooth device event");
    if (this.experienceMode == "Online") {
      this.showNewBluetoothDevice = true;
    } else if (this.experienceMode == null) {
      this.acceptNewDevice();
    }
  }
}
