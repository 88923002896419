import { PluginFunction } from "vue";

declare global {
   interface Window {
      jsBridge: IJSBridge;
   }
}


declare module "vue/types/vue" {
   interface Vue {
     $jsBridge: IJSBridge
   }
 }

export default class VueJsBridge {
   public static install: PluginFunction<void> = (__instance) => {
      __instance.prototype.$jsBridge = window.jsBridge;
   };
}

interface IJSBridge {
   getDeviceId(): string;
   requestReloadForNewBluetoothDevice(): void;
}
