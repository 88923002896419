import Vue from "vue";

import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { SignalrOptions } from ".";

declare module 'vue/types/vue' {
  interface Vue {
    $signalr: SignalRProxy
  }
}

export default class SignalRProxy extends Vue {
  private _connection: HubConnection;
  private _connectionStartPromise: Promise<void>;
  currentTime: number;
  reconnectedRegistered: boolean;
  public get connection(): HubConnection {
    return this._connection;
  }

  startConnection() {
    if (this._connection.state == HubConnectionState.Disconnected)
      return (this._connectionStartPromise = this._connection
        .start()
        .then(() => console.log("signalR connected"))
        .catch(reason => console.log("Can't connect:" + reason)));

    if (this._connection.state == HubConnectionState.Connecting) return this._connectionStartPromise;
    if (this._connection.state == HubConnectionState.Connected) return Promise.resolve();

    return Promise.reject();
  }

  stopConnection() {
    if (this._connection.state == HubConnectionState.Connected) {
      return this._connection.stop();
    }

    if (this._connection.state == HubConnectionState.Disconnected || this._connection.state == HubConnectionState.Disconnecting) {
      return Promise.resolve();
    }

    return Promise.reject();
  }

  constructor(options: SignalrOptions) {
    super();
    let builder = new HubConnectionBuilder().withUrl(options.url).configureLogging(options.logLevel || LogLevel.Information);
    if (options.automaticReconnectRetriesArray) {
      builder = builder.withAutomaticReconnect(options.automaticReconnectRetriesArray);
    } else if (options.automaticRetryCallback) {
      builder = builder.withAutomaticReconnect(options.automaticRetryCallback);
    } else {
      builder = builder.withAutomaticReconnect();
    }
    if (options.protocol) {
      builder = builder.withHubProtocol(options.protocol);
    }
    this._connection = builder.build();

    this.startConnection();

    this._connection.onreconnected(connectionId => {
      console.log("signalR reconnected");
      this.$emit("reconnected");
    });

    this._connection.onreconnecting(error => {
      console.log("disconnected, trying reconnecting");
      this.$emit("disconnected");
    });
    const self = this;
    const startTicks = function () {
      self._connection.on("TimeTick", function (value: number) {
        self.currentTime = value;
      });
    };
    this._connection.onreconnected(startTicks);
    startTicks();
  }

  onRecordingSessionUploadable(caller: any, callback: (caller: any, recordingSessiong: string) => void) {
    this._connection.off('RecordingSessionIsUploadable');
    this._connection.on('RecordingSessionIsUploadable', function(recordingSession: string) {
      callback.apply(caller, [recordingSession]);
    });
  }

  onCameraRecording(caller: any, callback: (caller: any, cameraId: number, recordingSession: string, timestamp: number) => void) {
    this._connection.off('CameraRecording');
    this._connection.on('CameraRecording', function (cameraId, recordingSession, timestamp) {
      callback.apply(caller, [caller, cameraId, recordingSession, timestamp]);
    });
  }

  onCameraStillPublishing(caller: any, callback: (caller: any, cameraId: number, booth: string) => void) {
    this._connection.off("CameraStillPublishing");
    this._connection.on("CameraStillPublishing", function (cameraId, booth) {
      callback.apply(caller, [caller, cameraId, booth]);
    });
  }

  async sendRefreshRequest(id: number) {
    await this._connection.send("SendRefreshRequest", id);
  }

  onRefreshRequest(callback: (requestingUser: string) => void) {
    this._connection.on("RefreshRequest", (userName: string) => callback(userName));
  }

  async joinBoothControl(uid: number, booth: string, role: string) {
    await this.send("JoinBoothControl", uid, booth, role);
  }

  async sendChangeCamera(cameraId: number, mode: string = 'Online', recordingSession: string = null, timestamp: number = 0, forceCameraReset: boolean = false, channelSuffix: string = null) {
    await this.send("ChangeCamera", cameraId, mode, recordingSession, timestamp, forceCameraReset, channelSuffix);
  }

  async sendCameraStillPublishing(cameraId: number, booth: string) {
    await this.send("CameraStillPublishing", cameraId, booth);
  }

  async sendCameraRecording(cameraId: number, recordingSession: string, timestamp: number, booth: string) {
    await this.send("CameraRecording", cameraId, recordingSession, timestamp, booth);
  }


  onJoinedBoothControl(caller: any, callback: (connectionId: string, booth: string, uid: number, role: string) => void): void {
    this._connection.off("JoinedBoothControl");
    this._connection.on("JoinedBoothControl", function (connectionId: string, booth: string, uid: number, role: string) {
      callback.apply(caller, [connectionId, booth, uid, role]);
    });
  }

  onLeftBoothControl(caller: any, callback: (connectionId: string, booth: string, uid: number, role: string) => void): void {
    this._connection.off("LeftBoothControl");
    this._connection.on("LeftBoothControl", function (connectionId: string, booth: string, uid: number, role: string) {
      callback.apply(caller, [connectionId, booth, uid, role]);
    });
  }

  onChangeCameraRequest(caller: any, callback: (cameraId: number, mode: 'Online' | 'InStore', session: string, timestamp: number, forceCameraReset: boolean, channelSuffix: string) => void) {
    this._connection.off("ChangeCameraRequest");
    this._connection.on("ChangeCameraRequest", function (cameraId: number, mode: 'Online' | 'InStore', session: string, timestamp: number, forceCameraReset: boolean, channelSuffix: string) {
      callback.apply(caller, [cameraId, mode, session, timestamp, forceCameraReset, channelSuffix]);
    });
  }

  onCallClosed(caller: any, callback: (callSlug: string) => void) {
    this._connection.off("CallClosed");
    this._connection.on("CallClosed", function (callSlug: string) {
      callback.apply(caller, [callSlug]);
    });
  }

  private async send(method: "JoinBoothControl" | "ChangeCamera" | "CameraStillPublishing" | "CameraRecording", ...args: any[]) {
    await this._connectionStartPromise;
    await this._connection.send.apply(this._connection, [method, ...args]);
  }
}
