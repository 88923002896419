












import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class Countdown extends Vue {
  @Prop({type: String, default: 'Countdown'}) name: string;
  @Prop({required: true, type: Number}) timer: number;
  @Prop({required: true, type: Function}) callback!: () => void;
  timerInterval: any;
  counter: string = "";

  mounted() {
    this.startTimer();
  }

  beforeDestroy() {
    clearInterval(this.timerInterval);
  }

  startTimer() {
    let timer:number = this.timer;
    let minutes:number, seconds:number;
    
    this.timerInterval = setInterval(() => {
      minutes = parseInt((timer / 60).toString(), 10);
      seconds = parseInt((timer % 60).toString(), 10);

      const mins:string = minutes < 10 ? "0" + minutes : minutes + "";
      const secs:string = seconds < 10 ? "0" + seconds : seconds + "";
      this.counter = `${mins}:${secs}`;

      if (--timer < 0) {
        console.log(`${this.name} timer elapsed, invoking callback`);
        clearInterval(this.timerInterval);
        this.callback();
      }
    }, 1000);
  }
}
