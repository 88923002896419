import { IMicrophoneAudioTrack } from "agora-rtc-sdk-ng";
import { DeviceTestBase, IDeviceTest } from "./IDeviceTest";
import { ErrorTestResult, FAILED, SUCCEEDED, TestResult } from "./TestResult";

export default class MicTest extends DeviceTestBase {
   failureReason: string;
   failureDescription: string;
   failureTitle: string;
   mic: IMicrophoneAudioTrack;

   get failedInfo(): string {
      return `${this.failureReason} - ${this.failureDescription}`;
   }

   constructor(public name: string, private vueInstance: Vue) {
      super(name);
   }

   protected async runLogic(forceKO: boolean = false): Promise<TestResult> {
      try {
         if (forceKO) throw 'FORCED KO FOR TESTS';
         this.mic = await this.vueInstance.$agoraClient.createAudioTrack();
         this.mic.getMediaStreamTrack().stop();
         return Promise.resolve(SUCCEEDED);
      } catch (error) {
         console.error('Error in mic test', error);
         switch (error?.code) {
            case 'NOT_SUPPORTED':
               this.failureReason = 'NOT SUPPORTED';
               this.failureDescription = 'Your browser or system does not support microphone stream creation';
               this.failureTitle = 'DEVICE_ERROR.MIC_NOT_SUPPORTED.TITLE';
               this.setSuggestions('DEVICE_ERROR.MIC_NOT_SUPPORTED.SUGGEST_1', 'DEVICE_ERROR.MIC_NOT_SUPPORTED.SUGGEST_2', 'DEVICE_ERROR.MIC_NOT_SUPPORTED.SUGGEST_3')
               break;
            case 'MEDIA_OPTION_INVALID':
               this.failureReason = 'microphone MIN CAPABILITIES NOT MET';
               this.failureDescription = 'Your microphone does not meet the minimum requirements for this application';
               this.failureTitle = 'DEVICE_ERROR.MIC_MEDIA_OPTION_NON_VALID.TITLE';
               this.setSuggestions('DEVICE_ERROR.MIC_MEDIA_OPTION_NON_VALID.SUGGEST_1')
               break;
            case 'DEVICE_NOT_FOUND':
               this.failureReason = 'DEVICE NOT FOUND';
               this.failureDescription = 'microphone device was not found';
               this.failureTitle = 'DEVICE_ERROR.MIC_DEVICE_NOT_FOUND.TITLE';
               this.setSuggestions('DEVICE_ERROR.MIC_DEVICE_NOT_FOUND.SUGGEST_1', 'DEVICE_ERROR.MIC_DEVICE_NOT_FOUND.SUGGEST_2', 'DEVICE_ERROR.MIC_DEVICE_NOT_FOUND.SUGGEST_3')
               break;
            case 'CONSTRAINT_NOT_SATISFIED':
               this.failureReason = 'NOT SUPPORTED';
               this.failureDescription = 'Your browser does not support requirements';
               this.failureTitle = 'DEVICE_ERROR.MIC_CONSTRAINT_NOT_SATISFIED.TITLE';
               this.setSuggestions('DEVICE_ERROR.MIC_CONSTRAINT_NOT_SATISFIED.SUGGEST_1', 'DEVICE_ERROR.MIC_CONSTRAINT_NOT_SATISFIED.SUGGEST_2', 'DEVICE_ERROR.MIC_CONSTRAINT_NOT_SATISFIED.SUGGEST_3', 'DEVICE_ERROR.MIC_CONSTRAINT_NOT_SATISFIED.SUGGEST_4', 'DEVICE_ERROR.MIC_CONSTRAINT_NOT_SATISFIED.SUGGEST_5')
               break;
            case 'NOT_READABLE':
               this.failureReason = 'microphone NOT READABLE';
               this.failureDescription = 'Something is preventing microphone to be accessed';
               this.failureTitle = 'DEVICE_ERROR.MIC_NOT_READABLE.TITLE';
               this.setSuggestions('DEVICE_ERROR.MIC_NOT_READABLE.SUGGEST_1', 'DEVICE_ERROR.MIC_NOT_READABLE.SUGGEST_2', 'DEVICE_ERROR.MIC_NOT_READABLE.SUGGEST_3')
               break;
            case 'PERMISSION_DENIED':
               this.failureReason = 'PERMISSION DENIED';
               this.failureDescription = 'You did not give permissions to use the microphone';
               this.failureTitle = 'DEVICE_ERROR.MIC_PERMISSION_DENIED.TITLE';
               this.setSuggestions('DEVICE_ERROR.MIC_PERMISSION_DENIED.SUGGEST_1', 'DEVICE_ERROR.MIC_PERMISSION_DENIED.SUGGEST_2')
               break;
            default:
               this.failureReason = 'ERROR';
               this.failureDescription = 'There have been an issue with your microphone';
               this.failureTitle = 'DEVICE_ERROR.MIC_ERROR.TITLE';
               this.setSuggestions('DEVICE_ERROR.MIC_ERROR.SUGGEST_1', 'DEVICE_ERROR.MIC_ERROR.SUGGEST_2', 'DEVICE_ERROR.MIC_ERROR.SUGGEST_3')
               break;
         }
         return new ErrorTestResult(error.toString(), error?.code ? 'AgoraMicrophoneIssue' : 'MicrophoneIssue', 'An issue occurred in MicTest:\n' + JSON.stringify(error, null, 2));
      }
   }

}