







import Vue from "vue";
import Component from "vue-class-component";
@Component({})
export default class NetworkCheck extends Vue {
  offline: boolean = false;
  checkInterval: number;

  constructor() {
    super();
    const vm = this;

window.addEventListener('online', () => { this.checkOffline() });
window.addEventListener('offline', () => { this.checkOffline() });
  }

  beforeDestroy() {
  }

  checkOffline() {
    if (navigator.onLine) {
      this.offline = false;
    } else {
      this.offline = true;
    }
  }
}
