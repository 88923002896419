














import IRTCStats from "@/agora-rtc/rtc-stats-interface";
import BaseVue from "@/zero/utilities/base-vue";
import { IAgoraRTCRemoteUser } from "agora-rtc-sdk-ng";
import { Component, Prop } from "vue-property-decorator";
@Component({
  filters: {
    bps: function(value: number) {
      const measures = ["bps", "Kbps", "Mbps"];
      let i = 0;
      let result = value.toFixed(2);
      while (value > 0) {
        value = value / 1024;
        if (value <= 1) break;
        if (i >= 2) break;
        result = value.toFixed(2);
        i++;
      }
      return `${result} ${measures[i]}`;
    }
  }
})
export default class Assistant extends BaseVue {
  uid = 9999;
  @Prop({
    default: "milano1"
  })
  booth: string;
  subscribedUsers: IAgoraRTCRemoteUser[] = [];
  rtcStatistics: IRTCStats = null;
  role = "assistant";

  async mounted() {
    try {
      console.log("mounting");
      const { occupied, message } = await this.$gooseapi.occupyBooth(this.booth, this.role);
      if (!occupied) {
        alert(message);
        this.$router.push("/");
        return;
      }
      await this.joinBoothControl(this.uid, this.$props.booth, this.role);
      await this.joinBoothChannel(this.uid, this.$props.booth);
      // await this.publishStreams(false, true);
      // await this.enableRTCProfile();
      this.$on("stats-available", function(stats: IRTCStats) {
        this.rtcStatistics = stats;
      });
    } catch (e) {
      console.error(e);
      alert("There was an error initializing the page, please refresh to retry");
    }
  }

  async onRemoteUserPublished(sender: Assistant, user: IAgoraRTCRemoteUser) {
    if ([1111, 3333].includes(+user.uid)) {
      if (!user.hasVideo && user.hasAudio) {
        await this.$agoraClient.subscribe(user, false, true);
        this.subscribedUsers[user.uid] = user;
      }
    }
  }

  async onRemoteUserUnpublished(sender: Assistant, user: IAgoraRTCRemoteUser) {
    if ([1111, 3333].includes(+user.uid)) {
      const subscribed: IAgoraRTCRemoteUser = this.subscribedUsers[user.uid];
      if (subscribed) {
        await this.$agoraClient.unsubscribe(subscribed, true, true);
        this.subscribedUsers[user.uid] = null;
      }
    }
  }
}
