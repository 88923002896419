export class ObjectStoreWrapper {
    db: IDBDatabase = null;
    store: IDBObjectStore = null;
    constructor(db: IDBDatabase, objectStore: IDBObjectStore) {
      this.db = db;
      this.store = objectStore;
    }

    put(value: any, key?: IDBValidKey) {
      return this.store.put(value, key)
    }
  
    index(indexName: string) {
      return this.store.index(indexName);
    }
  
    delete(key: IDBValidKey | IDBKeyRange) {
      return this.store.delete(key);
    }
  
    dispose() {
      this.db.close();
    }
  }
  