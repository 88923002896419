import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import LogSend, { ILogEntry, ILogSink } from ".";
import { SignalrOptions } from "../signalr";
import SignalRProxy from "../signalr/signalr-proxy";
export default class SignalRSink implements ILogSink {
  connection: HubConnection;
  remoteMethodName: string;
  constructor(connection: HubConnection | SignalrOptions, remoteMethodName: string) {
    if (connection instanceof HubConnection) this.connection = <HubConnection>connection;
    else {
      this.connection = new SignalRProxy(connection).connection;
    }
    this.remoteMethodName = remoteMethodName;
  }

  async writeLog(appName: string, entry: ILogEntry) {
    try {
      await this.ensureConnected();
      if (this.connection.state !== HubConnectionState.Connected) return;
      await this.connection.send(this.remoteMethodName, JSON.stringify(entry), appName);
    } catch (error) {
      // log lost for some error
    }
  }

  async ensureConnected() {
    return new Promise<void>((resolve, reject) => {
      if (this.connection.state === HubConnectionState.Connected) {
        resolve();
        return;
      }

      if (this.connection.state === HubConnectionState.Connecting || this.connection.state === HubConnectionState.Reconnecting) {
        const checkInterval = setInterval(() => {
          if (this.connection.state === HubConnectionState.Connected) {
            clearInterval(checkInterval);
            resolve();
          }
        }, 1000);
        return;
      }

      if (this.connection.state === HubConnectionState.Disconnected) {
        this.connection
          .start()
          .then(() => resolve())
          .catch(err => reject(err));
        return;
      }

      reject("Connection is disconnecting, adieux");
    });
  }
}
