




















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Component({})
export default class DownloadYourExperience extends Vue {
  checked: boolean = false;
  @Prop() session: string;
  @Prop() hash: string;
  apiHost: string;
  downloadLocation: string;

  /**
   *
   */
  constructor() {
    super();
    this.apiHost = process.env.VUE_APP_UPLOAD_API_URL || (/localhost/.test(location.href) ? "https://localhost:5001/api" 
                                                              : "https://gooseserverdev.eu.ngrok.io/api");
  }

  async mounted() {
    var response = await fetch(`${this.apiHost}/get-download-link/${this.session}/${this.hash}`);
    if (response.ok) {
      this.downloadLocation = await response.text();
      this.checked = true;
      location.href = this.downloadLocation + '?appId=sneakermaker';
    }
  }
}
