import Vue, { PluginFunction } from "vue";
import { UID } from "agora-rtc-sdk-ng";
import { RECORDING_BPS, RECORDING_CODEC } from "./constants";
import { IDBVideoSlice } from "../indexed-db-service";
import { createUUID } from "@/zero/utilities/uid-utils";
import { mergeSlicesInternal } from "../file-merger-worker/functions";
import { mergeSlices } from "../file-merger.worker";
export default class InStoreRecordingService extends Vue {
    recorder: MediaRecorder;
    recordingSession: string;
    recording: boolean;
    cameraId: UID;
    recordingTimestamp: number;
    cameraMediaStream: MediaStream;
    resolveRecorderStarted: (value: void | PromiseLike<void>) => void;
    recordingSessionStartedAt: Date;

    async startRecordingSession(cameraMediaStream: MediaStream, cameraId: number | UID, session: string = null, timestamp: number = null, override: boolean = false): Promise<{ recordingSession: string, timestamp: number }> {
        // if new session, set session start date
        if (override && session && timestamp) {
            await this.$dbService.deleteRecordingSession(session, timestamp);
        }

        if (!session || override)
            this.recordingSessionStartedAt = new Date();

        this.recordingSession = session || createUUID();
        this.recordingTimestamp = timestamp || Number(new Date());
        this.cameraId = cameraId;
        this.cameraMediaStream = cameraMediaStream;
        this.$emit('recording-session-starting', this.recordingSession);
        await this.startNewRecorder();
        this.recording = true;
        this.$emit('recording-session-started', this.recordingSession);
        return { recordingSession: this.recordingSession, timestamp: this.recordingTimestamp };
    }

    totalBlobsSize: number = 0;
    sliceIndex = 0
    sliceBuffer: IDBVideoSlice[] = [];
    storingSlices = false;
    async onRecorderDataAvailable(blob: Blob) {
        console.log(`onRecorderDataAvailable: RecordRTC data available event callback, adding temp blob of ${blob.size} bytes to blobs queue`);
        this.totalBlobsSize += blob.size;
        const currentSlice = new IDBVideoSlice(this.recordingSession, this.recordingTimestamp, this.cameraId, blob, this.sliceIndex);
        this.sliceBuffer.push(currentSlice);
        console.log(`slice n. ${this.sliceIndex} has been pushed in buffer`);
        this.sliceIndex++;
        if (!this.storingSlices) {
            this.storingSlices = true;
            do {
                const sliceToStore = this.sliceBuffer.splice(0, 1)[0];
                console.log(`slice n. ${sliceToStore.sliceIndex} has been popped from the buffer`)
                await this.$dbService.storeSessionSlice(sliceToStore);
            } while (this.sliceBuffer.length > 0);
            this.storingSlices = false;
        }
        else {
            console.warn('onRecorderDataAvailable: slices database still occupied by previous transaction(s) this slice has been put in buffer array');
        }
        console.log(`RecordRTC data available event callback, new blobs total size is ${this.totalBlobsSize} bytes`);
    }

    startNewRecorder() {
        this.sliceIndex = 0;
        this.totalBlobsSize = 0;
        const promise = new Promise<void>(resolve => {
            this.recorder = new MediaRecorder(this.cameraMediaStream, {
                mimeType: RECORDING_CODEC,
                videoBitsPerSecond: RECORDING_BPS,
                // getNativeBlob: true,
                // ondataavailable: (blob: Blob) => this.onRecorderDataAvailable(blob)
            });
            this.recorder.ondataavailable = (event: BlobEvent) => this.onRecorderDataAvailable(event.data)
            this.recorder.onstart = () => {
                if (this.recorder.state === "recording") {
                    resolve();
                }
            }
            // this.recorder.onStateChanged = (state) => {
            //     if (state === 'recording')
            //         resolve();
            // }
            this.recorder.start(5 * 1000);
        });
        return promise;
    }

    async onRecordingCameraChange(cameraId: number | UID) {
        if (this.recording) {
            if (cameraId == this.cameraId) return;
            await new Promise<void>((resolve, reject) => {
                this.recorder.onstop = async () => {
                    try {
                        await this.$dbService.setSlicesMergeable(this.recordingSession, this.recordingTimestamp);
                        mergeSlices(this.recordingSession, this.recordingTimestamp, this.cameraId, RECORDING_CODEC);
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                this.recorder.stop()
            });

            this.recording = false;
            this.recordingTimestamp = null;
        }
    }

    async stopRecordingSession(): Promise<void> {
        this.$emit('recording-session-stopping', this.recordingSession);
        await this.onRecordingCameraChange(null);
        if (this.recorder) {
            // this.recorder.clearRecordedData();
            this.recorder = null;
        }

        this.$emit('recording-session-stopped', this.recordingSession);
    }

    async markSessionTimings() {
        await this.$dbService.setSessionTimings(this.recordingSession, this.recordingSessionStartedAt, new Date());
    }

    private eventDelegate = document.createDocumentFragment();

    addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void {
        this.eventDelegate.addEventListener.apply(this.eventDelegate, [type, listener, options]);
    }
    dispatchEvent(event: Event): boolean {
        return this.eventDelegate.dispatchEvent.apply(this.eventDelegate, [event]);
    }
    removeEventListener(type: string, callback: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void {
        this.eventDelegate.removeEventListener.apply(this.eventDelegate, [type, callback, options]);
    }

}

export class InStoreRecordingServicePlugin {
    public static install: PluginFunction<void> = (
        __instance
    ) => {
        __instance.prototype.$instorerecording = new InStoreRecordingService();
    };
}

declare module "vue/types/vue" {
    interface Vue {
        $instorerecording: InStoreRecordingService;
    }
}

