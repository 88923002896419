














import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Component({})
export default class CustomCountdown extends Vue {
  @Prop() title: string;
  @Prop() subtitle: string;
  @Prop() body: string;
  @Prop({default: 60}) timer: number;
  @Prop({default: 'CustomCountdown', type: String}) name: string;
  shown: boolean = false;
  timeout: any = null;

  mounted() {
    this.startTimeout();
  }

  beforeDestroy() {
    this.deleteTimeout();
  }

  startTimeout() {
    this.shown = false;
    this.timeout = setTimeout(() => {
      console.log(`${this.name} custom countdown timed out, showing panel`);
      this.shown = true;
    }, this.timer * 1000);
  }

  deleteTimeout() {
    clearTimeout(this.timeout);
  }

  public resetTimeout() {
    this.deleteTimeout();
    this.startTimeout();
  }
}
