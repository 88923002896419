import { CameraType } from "@/zero/utilities/camera-type";
import { UID } from "agora-rtc-sdk-ng";
import Vue, { PluginFunction } from "vue"
import { extend } from "vue/types/umd";

export default class CamerasTracker extends Vue {
    public static install: PluginFunction<void> = (__instance) => {
        __instance.prototype.$camerasTracker = new CamerasTracker();
    }

    availableCameras: TrackedCamera[] = [];

    async addAvailableCamera(camera: CameraType | number | UID | string, connectionId: string) {
        if (!this.availableCameras.some(tc => tc.camera === +camera && tc.connectionId === connectionId)) {
            this.availableCameras.push(new TrackedCamera(+camera, connectionId));
            this.$emit('camera-available', +camera);
        }
    }

    removeAvailableCamera(camera: CameraType | number | UID | string, connectionId: string) {
        if (this.availableCameras.some(tc => tc.camera === +camera && tc.connectionId === connectionId)) {
            this.availableCameras.splice(this.availableCameras.findIndex(tc => tc.camera === +camera && tc.connectionId === connectionId), 1)
        }
        if (!this.availableCameras.some(tc => tc.camera === +camera)) { this.$emit('camera-left', +camera); }
    }

}

export class TrackedCamera {
    constructor(public camera: number, public connectionId: string) {

    }
}


declare module "vue/types/vue" {
    interface Vue {
        $camerasTracker: CamerasTracker
    }
}

