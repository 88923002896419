export default class Semaphore<TResult> {
   currentRequests: {resolve: (value: TResult | PromiseLike<TResult>) => void; reject: (reason?: any) => void;
   fnToCall: any, args: any[]}[];
   runningRequests: number;
   constructor(public maxConcurrentRequests = 1) {
      this.currentRequests = [];
      this.runningRequests = 0;
   }


   callFunction(fnToCall: any, ...args): Promise<TResult> {
      return new Promise<TResult>((resolve:(value: TResult | PromiseLike<TResult>) => void, reject: (reason?: any) => void) => {
         this.currentRequests.push({
            resolve,
            reject,
            fnToCall,
            args,
         });
         this.tryNext();
      });
   }

   tryNext() {
      if (!this.currentRequests.length) {
         return;
      } else if (this.runningRequests < this.maxConcurrentRequests) {
         const { resolve, reject, fnToCall, args } = this.currentRequests.shift();
         this.runningRequests++;
         const req = fnToCall(...args);
         req.then((res) => resolve(res))
            .catch((err) => reject(err))
            .finally(() => {
               this.runningRequests--;
               this.tryNext();
            });
      }
   }
}