import AgoraRTCClient from "@/agora-rtc";
import { CharacterSetValueIdentifiers } from "@zxing/library/esm/core/common/CharacterSetECI";
import { ICameraVideoTrack, IMicrophoneAudioTrack } from "agora-rtc-sdk-ng";
import Vue from "vue";
import CameraTest from "./camera-test";
import { DeviceTestBase, IDeviceTest } from "./IDeviceTest";
import MicTest from "./mic-test";
import { ErrorTestResult, FAILED, SUCCEEDED, TestResult } from "./TestResult";

export default class AgoraTest extends DeviceTestBase {
   private _failedInfo: string;
   agoraClient: AgoraRTCClient;
   failureTitle: string;

   get failedInfo(): string {
      return this._failedInfo;
   }

   constructor(public name: string, private vueInstance: Vue) {
      super(name);
   }

   protected async runLogic(): Promise<TestResult> {
      let result: TestResult;
      try {
         this.agoraClient = this.vueInstance.$agoraClient.clone();

         const channel = 'test-channel-' + Number(new Date()).toString();
         const { tokenResult, agoraToken } = await this.tryGetAgoraToken(channel);
         if (!tokenResult.succeeded) {
            result = tokenResult;
            return result;
         }

         const joinresult: TestResult = await this.tryJoin(channel, agoraToken);
         if (!joinresult.succeeded) {
            result = joinresult;
            return result;
         }

         const { camresult, camera } = await this.tryGetCamera();
         if (!camresult.succeeded) {
            result = camresult;
            return result;
         }

         const { micresult, mic } = await this.tryGetMicrophone();
         if (!micresult.succeeded) {
            result = micresult;
            return result;
         }

         const publishResult: TestResult = await this.tryPublish(camera, mic);
         if (!publishResult.succeeded) {
            result = publishResult;
            return result;
         }

         await this.agoraClient.unpublish();

         await this.agoraClient.leave();

         camera.getMediaStreamTrack().stop();
         mic.getMediaStreamTrack().stop();

         this.agoraClient = null;

         result = SUCCEEDED;
         return result;

      } catch (error) {
         result = new ErrorTestResult(error.toString(), 'Unknown', 'An unknown issue occurred \n' + JSON.stringify(error, null, 2));
         return result;
      }
   }

   async tryGetAgoraToken(channel: string): Promise<{ tokenResult: TestResult; agoraToken?: string; }> {
      try {
         const agoraToken = await this.vueInstance.$gooseapi.getAgoraToken(channel, 9999, false)
         return { tokenResult: SUCCEEDED, agoraToken };
      } catch (error) {
         console.error(`Error in ${this.name} tryGetAgoraToken`, error);
         this._failedInfo = 'TOKEN ERROR - Could not initialize an authentication token';
         this.failureTitle = 'DEVICE_ERROR.AGORA_TOKEN_ISSUE.TITLE';
         this.addSuggestions(
            'DEVICE_ERROR.AGORA_TOKEN_ISSUE.SUGGEST_1'
         );
         return { tokenResult: new ErrorTestResult(error.toString(), 'AgoraTokenIssue', `An error occurred  while trying to get an Token for channel ${channel}, user 9999:\n${JSON.stringify(error, null, 2)}`) }
      }
   }

   async tryPublish(camera: ICameraVideoTrack, mic: IMicrophoneAudioTrack): Promise<TestResult | PromiseLike<TestResult>> {
      try {
         await this.agoraClient.publishTracks([camera, mic]);
         return SUCCEEDED;
      } catch (error) {
         console.error(`Error in ${this.name} tryPublish`, error);
         switch (error?.code) {
            case 'NO_ICE_CANDIDATE':
               this._failedInfo = 'WEBRTC ERROR - We could not connect to communication channel'
               this.failureTitle = 'DEVICE_ERROR.AGORA_NO_ICE_CANDIDATE_ISSUE.TITLE';
               this.addSuggestions(
                  'DEVICE_ERROR.AGORA_NO_ICE_CANDIDATE_ISSUE.SUGGEST_1',
                  'DEVICE_ERROR.AGORA_NO_ICE_CANDIDATE_ISSUE.SUGGEST_2',
                  'DEVICE_ERROR.AGORA_NO_ICE_CANDIDATE_ISSUE.SUGGEST_3',
                  'DEVICE_ERROR.AGORA_NO_ICE_CANDIDATE_ISSUE.SUGGEST_4'
               );
               return new ErrorTestResult(error.toString(), 'AgoraNoIceCandidateIssue', `A NO_ICE_CANDIDATE error occurred  trying to publish tracks:\n${JSON.stringify(error, null, 2)}`);

            default:
               this._failedInfo = `${(error?.code || 'UNEXPECTED ERROR')} - Issues with this page`;
               this.failureTitle = 'DEVICE_ERROR.AGORA_ERROR.TITLE';
               this.addSuggestions(
                  'DEVICE_ERROR.AGORA_ERROR.SUGGEST_1',
                  'DEVICE_ERROR.AGORA_ERROR.SUGGEST_2',
                  'DEVICE_ERROR.AGORA_ERROR.SUGGEST_3'
               )
               return new ErrorTestResult(error.toString(), 'AgoraPublishTrackIssue', `An error occurred  trying to publish tracks:\n${JSON.stringify(error, null, 2)}`);
               break;
         }
      }
   }

   async tryGetMicrophone(): Promise<{ micresult: TestResult; mic?: IMicrophoneAudioTrack; }> {
      const test = await this.runTest(new MicTest('', this.vueInstance)) as MicTest;
      return { micresult: test.result, mic: test.mic };
   }

   async tryGetCamera(): Promise<{ camresult: TestResult, camera: ICameraVideoTrack }> {
      const test = await this.runTest(new CameraTest('', this.vueInstance)) as CameraTest;
      return { camresult: test.result, camera: test.camera };
   }

   async runTest(test: IDeviceTest): Promise<IDeviceTest> {
      const result = await test.run(false);
      if (!result.succeeded) {
         this._failedInfo = test.failedInfo;
         this.setSuggestions(...test.suggestions);
      }
      return test;
   }


   async tryJoin(channel: string, agoraToken: string): Promise<TestResult | PromiseLike<TestResult>> {
      try {
         await this.agoraClient.join(channel, 9999, agoraToken);
         return SUCCEEDED;
      } catch (error) {
         console.error(`Error in ${this.name} tryJoin`, error);
         switch (error?.code) {
            default:
               this._failedInfo = 'INVALID PARAMETERS - Something is wrong with the setup of this page';
               this.setSuggestions('Ask support for help')
               break;
         }
         return new ErrorTestResult(error.toString(), 'AgoraJoinIssue', `An error occurred  while trying to join channel ${channel} with token ${agoraToken}:\n${JSON.stringify(error, null, 2)}`);
      }
   }

}