import { getEventDescription } from "@sentry/utils";
import { UID } from "agora-rtc-sdk-ng";
import dayjs from "dayjs";
import { PluginFunction } from "vue";
import { AgoraStopResult } from "../agora-recordings-apis";
import { Session } from "../file-worker/uploader";
import { ObjectStoreWrapper } from "./object-store-wrapper";

export const RECORDING_SLICES_DB_NAME = 'SM-recording-slices';
export const RECORDING_SLICES_DB_VERSION = 1;
export const RECORDING_SLICES_STORE_NAME = "videoSlices";

export const RECORDED_VIDEOS_DB_NAME = "SM-RecordedVideos";
export const RECORDED_VIDEOS_DB_VERSION = 5;
export const RECORDED_VIDEOS_STORE_NAME = "videos";
export const RECORDED_SESSIONS_STORE_NAME = "sessions";

// export const AGORA_RECORDINGS_DB_NAME = "SM-AgoraRecordings";
// export const AGORA_RECORDINGS_DB_VERSION = 1;
// export const AGORA_RECORDINGS_STORE_NAME = 'recordings'
export default class IndexedDbService {
    async deleteRecordingSession(session: string, timestamp: number) {
        await this.removeSessionSlices(session, timestamp);
        await this.deleteRecordingSessionFiles(session);
    }

    async deleteRecordingSessionFiles(session: string) {
        const db = await this.getVideosDb();
        const transaction = this.openDbTransaction(db, RECORDED_VIDEOS_STORE_NAME, 'readwrite');
        let transactionState = 'running';
        transaction.oncomplete = event => {
            transactionState = 'complete';
            db.close();
        }
        transaction.onabort = function (err: any) {
            transactionState = 'aborted';
            db.close();
        }
        const store = transaction.objectStore(RECORDED_VIDEOS_STORE_NAME);
        const index = store.index('idx_session');
        const kr = IDBKeyRange.only(session);
        const allKeysReq = index.getAllKeys(kr);
        const deletableKeys = await new Promise<string[]>((resolve, reject) => {
            allKeysReq.onsuccess = (event: any) => {
                resolve(event.target.result);
            }
            allKeysReq.onerror = (event: any) => {
                reject(event.target.error);
            }
        }).catch(error => {
            return [];
        });
        for (let index = 0; index < deletableKeys.length; index++) {
            if (transactionState != 'running')
                break;
            const element = deletableKeys[index];
            const deleteReq = store.delete(IDBKeyRange.only(element));
            const deleted = await new Promise<boolean>((resolve, reject) => {
                deleteReq.onsuccess = event => resolve(true);
                deleteReq.onerror = event => resolve(false);
            });
            if (!deleted) break;
        }

    }

    async deleteUploadedVideos() {
        const db = await this.getVideosDb();
        const transaction = this.openDbTransaction(db, RECORDED_VIDEOS_STORE_NAME, 'readwrite');
        let transactionState = 'running';
        transaction.oncomplete = event => {
            transactionState = 'complete';
            db.close();
        }
        transaction.onabort = function (err: any) {
            transactionState = 'aborted';
            db.close();
        }
        const store = transaction.objectStore(RECORDED_VIDEOS_STORE_NAME);
        const index = store.index('idx_uploadStatus');
        const kr = IDBKeyRange.only('uploaded');
        const allKeysReq = index.getAllKeys(kr);
        const deletableKeys = await new Promise<string[]>((resolve, reject) => {
            allKeysReq.onsuccess = (event: any) => {
                resolve(event.target.result);
            }
            allKeysReq.onerror = (event: any) => {
                reject(event.target.error);
            }
        }).catch(error => {
            return [];
        });
        for (let index = 0; index < deletableKeys.length; index++) {
            if (transactionState != 'running')
                break;
            const element = deletableKeys[index];
            const deleteReq = store.delete(IDBKeyRange.only(element));
            const deleted = await new Promise<boolean>((resolve, reject) => {
                deleteReq.onsuccess = event => resolve(true);
                deleteReq.onerror = event => resolve(false);
            });
            if (!deleted) break;
        }
    }

    async getDanglingSlicesSets(): Promise<{ session: string; timestamp: number; cameraId: UID; mergeStatus: MergeStatus; }[]> {
        const db = await this.getSlicesDb();
        const transaction = this.openDbTransaction(db, RECORDING_SLICES_STORE_NAME, 'readwrite');
        const slicesStore = transaction.objectStore(RECORDING_SLICES_STORE_NAME);
        const index = slicesStore.index("idx_sliceIndex")
        transaction.oncomplete = function () {
            db.close();
        }
        transaction.onabort = function (err: any) {
            db.close();
        }
        const kr = IDBKeyRange.only([0]);
        const getReq = index.getAll(kr);
        return new Promise<{ session: string; timestamp: number; cameraId: UID; mergeStatus: MergeStatus; }[]>((resolve, reject) => {
            getReq.onsuccess = (event: any) => resolve(event.target.result.map(item => {
                return {
                    session: item.session,
                    timestamp: item.timestamp,
                    cameraId: item.cameraId,
                    mergeStatus: item.mergeStatus
                }
            }));
            getReq.onerror = (event: any) => reject(event.target.error);
        })
    }

    async removeSessionSlices(session: string, timestamp: number) {
        const db = await this.getSlicesDb();
        const transaction = this.openDbTransaction(db, RECORDING_SLICES_STORE_NAME, 'readwrite');
        const slicesStore = transaction.objectStore(RECORDING_SLICES_STORE_NAME);
        const index = slicesStore.index("idx_session_timestamp")
        transaction.oncomplete = function () {
            db.close();
        }
        transaction.onabort = function (err: any) {
            db.close();
        }
        const kr = IDBKeyRange.only([session, timestamp]);
        const slices: IDBVideoSlice[] = await new Promise<IDBVideoSlice[]>((resolve, reject) => {
            const getReq = index.getAll(kr);
            getReq.onsuccess = async (event: any) => {
                const slicesResult = event.target.result.map(item => IDBVideoSlice.parseFromObject(item));
                resolve(slicesResult);
            };
            getReq.onerror = function (event: any) {
                reject(event.target.error);
            };
        });

        await Promise.all(slices.sort((s1, s2) => s2.sliceIndex - s1.sliceIndex).map(async slice => {
            new Promise<void>((resolve) => {
                const deleteReq = slicesStore.delete(slice.id)
                deleteReq.onsuccess = () => resolve();
                deleteReq.onerror = (event: any) => resolve(event.target.error);
            })
        }));
    }

    async setSlicesMerged(session: string, timestamp: number) {
        await this.setSlicesMergeStatus(session, timestamp, "merged")
    }

    async setSlicesMergeable(session: string, timestamp: number) {
        await this.setSlicesMergeStatus(session, timestamp, "mergeable")
    }

    async setSlicesMergeStatus(session: string, timestamp: number, status: MergeStatus) {
        const db = await this.getSlicesDb();
        const transaction = this.openDbTransaction(db, RECORDING_SLICES_STORE_NAME, 'readwrite');
        const slicesStore = transaction.objectStore(RECORDING_SLICES_STORE_NAME);
        const index = slicesStore.index("idx_session_timestamp_sliceIndex")
        transaction.oncomplete = function () {
            db.close();
        }
        transaction.onabort = function (err: any) {
            db.close();
        }
        const kr = IDBKeyRange.only([session, timestamp, 0]);
        const slice: IDBVideoSlice = await new Promise<IDBVideoSlice>((resolve, reject) => {
            const getReq = index.get(kr);
            getReq.onsuccess = async (event: any) => {
                const slice = IDBVideoSlice.parseFromObject(event.target.result);
                resolve(slice);
            };
            getReq.onerror = function (event: any) {
                reject(event.target.error);
            };
        });

        slice.mergeStatus = status;

        return new Promise<void>((resolve, reject) => {
            const putReq = slicesStore.put(slice);
            putReq.onsuccess = () => {
                resolve();
            }
            putReq.onerror = (event: any) => {
                reject(event.target.error);
            }
        })
    }

    openDbTransaction(db: IDBDatabase, storeName: string, mode: IDBTransactionMode): IDBTransaction {
        const trn = db.transaction(storeName, mode)
        return trn;
    }

    async getSlicesForSessionAndTimestamp(session: string, timestamp: number): Promise<IDBVideoSlice[]> {
        const db = await this.getSlicesDb();
        const transaction = this.openDbTransaction(db, RECORDING_SLICES_STORE_NAME, 'readonly');
        const slicesStore = transaction.objectStore(RECORDING_SLICES_STORE_NAME);
        const index = slicesStore.index("idx_session_timestamp")
        transaction.oncomplete = function () {
            db.close();
        }
        transaction.onabort = function (err: any) {
            db.close();
        }
        const kr = IDBKeyRange.only([session, timestamp]);
        return new Promise<IDBVideoSlice[]>((resolve, reject) => {
            const getReq = index.getAll(kr);
            getReq.onsuccess = function (event: any) {
                resolve(event.target.result);
            };
            getReq.onerror = function (event: any) {
                reject(event.target.error);
            };
        });
    }
    async storeSessionSlice(slice: IDBVideoSlice) {
        const { session, timestamp, cameraId, blob, sliceIndex } = slice;
        if ("storage" in navigator && "estimate" in navigator.storage) {
            navigator.storage.estimate().then(({ usage, quota }) => {
                usage = Math.floor(usage / 1024 / 1024);
                quota = Math.floor(quota / 1024 / 1024);
                console.log(`Storing session slice for session ${session}, video n. ${timestamp}, camera ${cameraId}, slice n. ${sliceIndex} with ${quota - usage} Mbytes available`);
            });
        }
        const db = await this.getSlicesDb();
        const transaction = this.openDbTransaction(db, RECORDING_SLICES_STORE_NAME, 'readwrite');
        return new Promise<void>((resolve, reject) => {
            transaction.oncomplete = function () {
                console.log(`Store session slice transaction completed for session ${session}, video n. ${timestamp}, camera ${cameraId}, slice n. ${sliceIndex}`);
                db.close();
                resolve();
            }
            transaction.onabort = function (err: any) {
                const error = err.target.error;
                const innerError = error?.inner;
                console.error(`Store session slice transaction failed for session ${session}, video n. ${timestamp}, camera ${cameraId}, slice n. ${sliceIndex} with error ${innerError || error}${(innerError ? " (is inner)" : "")}`);
                db.close();
                reject(err);
            }
            const objectStore = transaction.objectStore(RECORDING_SLICES_STORE_NAME);
            const addReq = objectStore.add(slice);
            addReq.onsuccess = function () { }
            addReq.onerror = function (err: any) {
                console.error(`Failed to store slice for session ${session}, video n. ${timestamp}, camera ${cameraId}, slice n. ${sliceIndex}: ${JSON.stringify(err?.inner || err)}`)

            }
        })
    }

    async resetUploadErrorVideos() {
        const sessionStore = await this.getObjectStore(RECORDED_VIDEOS_STORE_NAME, "readwrite");
        const kr = IDBKeyRange.only("upload-error");
        const getRequest = sessionStore.index('idx_uploadStatus').getAll(kr);
        const videos = await new Promise<IDBVideo[]>(resolve => {
            getRequest.onsuccess = function (event: any) {
                const result = event.target.result.map(item => IDBVideo.parseFromObject(item));
                resolve(result);
            }

            getRequest.onerror = function (event) {
                console.error(event);
                resolve(null);
            }
        });
        if (!videos || videos.length == 0) return;
        console.log('Starting upload error videos reset for ' + videos.length + ' videos');
        videos.forEach(async v => {
            v.resetUploadStatus();
            await this.updateVideo(v);
        })
        console.log('Upload error videos reset finished');

    }

    async updateVideo(video: IDBVideo) {
        const db = await this.getVideosDb();
        const transaction = this.openDbTransaction(db, RECORDED_VIDEOS_STORE_NAME, "readwrite");
        const videoStore = transaction.objectStore(RECORDED_VIDEOS_STORE_NAME);
        const putRequest = videoStore.put(video);
        transaction.oncomplete = function () {
            db.close();
        }
        transaction.onabort = function () {
            db.close();
        }
        return new Promise<boolean>((resolve, reject) => {
            putRequest.onsuccess = function () {
                resolve(true);
            };
            putRequest.onerror = function (event: any) {
                reject(event.target.error);
            }
        });
    }


    async markErrorSession(uploadable: Session) {
        const sessionStore = await this.getObjectStore(RECORDED_SESSIONS_STORE_NAME, "readwrite");
        const putRequest = sessionStore.put({ ...uploadable, state: 'uploadError' });

        const promise = new Promise<boolean>(resolve => {
            putRequest.onsuccess = function (event) {
                console.log(`session ${uploadable.session} has been marked as uploadError`);
                resolve(true);
            };

            putRequest.onerror = function (event) {
                console.error(`session ${uploadable.session} could not be marked as uploadError`, event);
                resolve(false);
            }
        });
        promise.catch(error => {
            console.error(`session ${uploadable.session} could not be marked as uploadError`, error);
            return Promise.resolve(false);
        }).finally(() => sessionStore.dispose());

        return await promise;
    }

    async getSessionTimings(session: string): Promise<{ startedAt: any; endedAt: any; }> {
        const sessionStore = await this.getObjectStore(RECORDED_SESSIONS_STORE_NAME, "readonly");
        const uploadableSessions = await new Promise<any>(resolve => {
            const keysReq = sessionStore.store.get(session)
            keysReq.onsuccess = function (event: any) {
                resolve(event.target.result);
            }
            keysReq.onerror = function (reason: any) {
                resolve([])
            }
        }).finally(() => sessionStore.dispose());
        if (!uploadableSessions) return { startedAt: null, endedAt: null };
        const { startedAt, endedAt } = uploadableSessions;
        return { startedAt, endedAt };
    }

    async setSessionTimings(session: string, startedAt: Date, endedAt: Date) {
        const sessionStore = await this.getObjectStore(RECORDED_SESSIONS_STORE_NAME, "readwrite");

        const putRequest = sessionStore.put({ session, startedAt, endedAt });
        const promise = new Promise<boolean>(resolve => {
            putRequest.onsuccess = function (event) {
                console.log(`session ${session} has been marked as uploadable`);
                resolve(true);
            };

            putRequest.onerror = function (event) {
                console.error(`session ${session} could not be marked as uploadable`, event);
                resolve(false);
            }
        });
        promise.catch(error => {
            console.error(`session ${session} could not be marked as uploadable`, error);
            return Promise.resolve(false);
        }).finally(() => sessionStore.dispose());

        return await promise;
    }

    async deleteVideosOlderThanDays(days: number) {
        const daysFromNow = dayjs().add(-1 * days, 'day');
        const videoStore = await this.getObjectStore('videos', 'readwrite');
        const deletableKeys: IDBValidKey[] = [];
        await new Promise<void>(resolve => {
            const cursorReq = videoStore.index('idx_createdAt').openKeyCursor();
            cursorReq.onsuccess = function (event: any) {
                const cursor = event.target.result as IDBCursor;
                if (cursor) {
                    const createdAt = dayjs(cursor.key as Date)
                    if (createdAt < daysFromNow) {
                        deletableKeys.push(cursor.primaryKey);
                    }
                    cursor.continue();
                    return;
                }
                resolve()
            }
            cursorReq.onerror = function (reason: any) {
                resolve();
            }
        })

        if (deletableKeys.length > 0) {
            deletableKeys.forEach(async k => {
                await new Promise<void>(resolve => {
                    const deleteReq = videoStore.delete(k);

                    deleteReq.onsuccess = function () {
                        resolve();
                    }
                    deleteReq.onerror = function () {
                        resolve();
                    }
                });
            })
        }
    }

    async getNonUploadedVideosSessions(): Promise<string[]> {
        const sessionStore = await this.getObjectStore(RECORDED_SESSIONS_STORE_NAME, "readonly");
        const uploadableSessions = await new Promise<string[]>(resolve => {
            const keysReq = sessionStore.store.getAllKeys()
            keysReq.onsuccess = function (event: any) {
                resolve(event.target.result);
            }
            keysReq.onerror = function (reason: any) {
                resolve([])
            }
        }).finally(() => sessionStore.dispose());

        const videoStore = await this.getObjectStore(RECORDED_VIDEOS_STORE_NAME, "readonly");

        const sessions = await new Promise<string[]>((resolve, reject) => {

            const result: string[] = [];
            const allKeysReq = videoStore.index('idx_session').openKeyCursor();
            allKeysReq.onsuccess = function (event: any) {

                const cursor = event.target.result as IDBCursor;
                if (cursor) {
                    result.push(cursor.key.toString());
                    cursor.continue();
                    return;
                }
                resolve(result);
            };
            allKeysReq.onerror = function (event: any) {
                reject(event);
            }
        }).finally(() => videoStore.dispose());

        return sessions.filter(s => !uploadableSessions.includes(s));
    }

    async removeUploadable(uploadable) {
        try {
            await this.removeAllSessionVideos(uploadable.session);
            const sessionsStore = await this.getObjectStore(RECORDED_SESSIONS_STORE_NAME, "readwrite");
            const delReq = sessionsStore.store.delete(uploadable.session);

            return await new Promise(resolve => {
                delReq.onsuccess = function () {
                    console.log(`session ${uploadable.session} successfully deleted`);
                    resolve(true);
                };
                delReq.onerror = function (event: any) {
                    console.log(
                        `session ${uploadable.session} could not be deleted`,
                        event
                    );
                    resolve(false);
                };
            }).finally(() => {
                sessionsStore.dispose();
            });
        } catch (error) {
            console.error("could not remove uploadable session", error);
        }
    }

    async removeAllSessionVideos(session) {
        const videosStore = await this.getObjectStore(RECORDED_VIDEOS_STORE_NAME, "readwrite");
        const kr = IDBKeyRange.only(session);
        const cursorRequest = videosStore.index("idx_session").openCursor(kr);
        const keysToBeDeleted = [];
        await new Promise(resolve => {
            cursorRequest.onsuccess = function (event: any) {
                const cursor = event.target.result;

                if (!cursor) {
                    resolve(true);
                    return;
                }
                keysToBeDeleted.push(cursor.primaryKey);
                cursor.continue();
            };

            cursorRequest.onerror = function (err) {
                console.error("could not create cursor", err);
                resolve(false);
            };
        });

        const allDelete = keysToBeDeleted.map(k => new Promise<void>(resolve => (videosStore.delete(k).onsuccess = function () { resolve(); })));
        await Promise.all(allDelete);
        videosStore.dispose();
    }


    async getNotUploadedVideos(): Promise<IDBVideo[]> {
        const db = await this.getVideosDb();
        const videoStore = db
            .transaction(RECORDED_VIDEOS_STORE_NAME, "readwrite")
            .objectStore(RECORDED_VIDEOS_STORE_NAME);
        const kr = IDBKeyRange.only("not-uploaded");
        return await new Promise<IDBVideo[]>(resolve => {
            videoStore.index('idx_uploadStatus').getAll(kr).onsuccess = function (event: any) {
                const x: IDBVideo[] = (event.target.result).map(item => IDBVideo.parseFromObject(item));
                resolve(x);
            };
        }).finally(() => {
            db.close();
        })
    }

    async getUploadableSession(): Promise<Session> {
        try {
            const sessionsStore = await this.getObjectStore(RECORDED_SESSIONS_STORE_NAME, "readonly");
            const kr = IDBKeyRange.only("uploadable");
            return await new Promise<Session>(resolve => {
                const getReq = sessionsStore.index("idx_state").get(kr);
                getReq.onsuccess = function (event: any) {
                    resolve(event.target.result);
                };
            })
                .catch(reason => {
                    console.error("error in getUploadableSession", reason);
                    return Promise.resolve(null);
                })
                .finally(() => {
                    sessionsStore.dispose();
                });
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    async getObjectStore(storeName, storeMode) {
        const db = await this.getVideosDb();
        const objectStore = db
            .transaction(storeName, storeMode)
            .objectStore(storeName);

        return new ObjectStoreWrapper(db, objectStore);
    }


    public static install: PluginFunction<void> = (
        __instance
    ) => {
        __instance.prototype.$dbService = new IndexedDbService();
    };

    async setSessionUploadable(session: string) {
        const db = await this.getVideosDb();
        const sessionsStore = db
            .transaction(RECORDED_SESSIONS_STORE_NAME, "readwrite")
            .objectStore(RECORDED_SESSIONS_STORE_NAME);

        const putRequest = sessionsStore.put({ session, state: 'uploadable' });
        const promise = new Promise<boolean>(resolve => {
            putRequest.onsuccess = function (event) {
                console.log(`session ${session} has been marked as uploadable`);
                resolve(true);
            };

            putRequest.onerror = function (event) {
                console.error(`session ${session} could not be marked as uploadable`, event);
                resolve(false);
            }
        });
        promise.catch(error => {
            console.error(`session ${session} could not be marked as uploadable`, error);
            return Promise.resolve(false);
        }).finally(() => db.close());

        return await promise;
    }


    async storeSessionVideo(session: string, databytes: Blob, timestamp: number, type: string, cameraId: number | string): Promise<void> {
        const videoObject: IDBVideo = new IDBVideo(`${session}_${timestamp}`, session, databytes, type, cameraId);
        const db = await this.getVideosDb();
        await new Promise<void>((resolve, reject) => {
            console.log(`Starting storeSessionVideo transaction for saving video ${session}_${timestamp}_${cameraId} in IndexedDB`)
            const transaction = this.openDbTransaction(db, RECORDED_VIDEOS_STORE_NAME, "readwrite");
            const videoStore = transaction.objectStore(RECORDED_VIDEOS_STORE_NAME);
            const reqStore = videoStore.add(videoObject);
            reqStore.onsuccess = function () {
                console.log(`Adding request succeeded for saving video ${session}_${timestamp}_${cameraId} in IndexedDB`)
            };
            reqStore.onerror = function (err) {
                console.log(`Adding request failed for saving video ${session}_${timestamp}_${cameraId} in IndexedDB`)
            };
            transaction.oncomplete = function (ev) {
                console.log(`Transaction completed for saving video ${session}_${timestamp}_${cameraId} in IndexedDB`)
                resolve();
                db.close();
            };
            transaction.onabort = function (err: any) {
                console.error(`Transaction abortedsaving video ${session}_${timestamp}_${cameraId} in IndexedDB: ${err.target.error}`);
                reject(err);
                db.close();
            };
        });
    }

    getSlicesDb(): Promise<IDBDatabase> {
        return this.getDb(RECORDING_SLICES_DB_NAME, RECORDING_SLICES_DB_VERSION);
    }

    getVideosDb(): Promise<IDBDatabase> {
        return this.getDb(RECORDED_VIDEOS_DB_NAME, RECORDED_VIDEOS_DB_VERSION);
    }

    getDb(dbName: string, dbVersion: number): Promise<IDBDatabase> {
        const me = this;
        return new Promise<IDBDatabase>((resolve, reject) => {
            const request = indexedDB.open(dbName, dbVersion);

            request.onsuccess = function (event: any) {
                resolve(event.target.result);
            };
            request.onerror = function (e: any) {
                console.error(`Error opening database ${dbName} version ${dbVersion}: ${e}`, e);
                if (typeof (window) !== 'undefined')
                    window.alert("non hai dato i permessi!\n\n" + e);
                reject(e);
            };
            request.onupgradeneeded = (event: any) => {
                const db = event.target.result as IDBDatabase;
                const e = event as IDBVersionChangeEvent;
                switch (db.name) {
                    case RECORDED_VIDEOS_DB_NAME:
                        this.upgradeRecordedVideosDb(db, event, e.oldVersion, e.newVersion);
                        break;
                    case RECORDING_SLICES_DB_NAME:
                        this.upgradeRecordingSlicesDb(db, event, e.oldVersion, e.newVersion);
                        break;
                    // case AGORA_RECORDINGS_DB_NAME:
                    //     this.upgradeAgoraRecordingsDb(db, event, e.oldVersion, e.newVersion);
                    //     break;
                }
                const RECORDING_SLICES_STORENAME = "videoSlices";

                console.log("Db upgraded");
            };
        });
    }
    // upgradeAgoraRecordingsDb(db: IDBDatabase, event: any, oldVersion: number, newVersion: number) {
    //     let recordings: IDBObjectStore = null;
    //     if (oldVersion < 1) {
    //         recordings = db.createObjectStore(AGORA_RECORDINGS_STORE_NAME, { keyPath: 'session' });
    //     }
    // }

    upgradeRecordingSlicesDb(db: IDBDatabase, event: any, oldVersion: number, newVersion: number) {
        let slicesStore: IDBObjectStore = null;
        if (oldVersion < 1) {
            slicesStore = db.createObjectStore(RECORDING_SLICES_STORE_NAME, { keyPath: "id" });
            slicesStore.createIndex("idx_session_timestamp", ['session', 'timestamp']);
            slicesStore.createIndex("idx_session_timestamp_sliceIndex", ['session', 'timestamp', 'sliceIndex']);
            slicesStore.createIndex("idx_sliceIndex", "sliceIndex");
        } else {
            slicesStore = event.currentTarget.transaction.objectStore(RECORDING_SLICES_STORE_NAME);
        }
    }

    upgradeRecordedVideosDb(db: IDBDatabase, event: any, oldVersion: number, newVersion: number) {
        let videoStore: IDBObjectStore = null;
        if (oldVersion < 1) {
            videoStore = db.createObjectStore(RECORDED_VIDEOS_STORE_NAME, { keyPath: "id" });
            videoStore.createIndex("idx_createdAt", 'createdAt');
            videoStore.createIndex("idx_session", 'session');
        } else {
            videoStore = event.currentTarget.transaction.objectStore(RECORDED_VIDEOS_STORE_NAME);
        }
        let sessionsStore: IDBObjectStore = null;
        if (oldVersion < 2) {
            sessionsStore = db.createObjectStore(RECORDED_SESSIONS_STORE_NAME, { keyPath: "session" });
            sessionsStore.createIndex("idx_uploadable", "uploadable");
        } else {
            sessionsStore = event.currentTarget.transaction.objectStore(RECORDED_SESSIONS_STORE_NAME);
        }
        if (oldVersion < 3) {
            sessionsStore.deleteIndex("idx_uploadable");
            sessionsStore.createIndex("idx_state", "state");
        }
        if (oldVersion < 4) {
            videoStore.createIndex("idx_uploadStatus", "uploadStatus");
        }
    }
}

export type MergeStatus = "not-merged" | "mergeable" | "merged";

export class IDBVideoSlice {
    id: string;
    mergeStatus: MergeStatus;
    static parseFromObject(source: any): IDBVideoSlice {
        return new IDBVideoSlice(source.session, source.timestamp, source.cameraId, source.blob, source.sliceIndex, source.mergeStatus, source.id);
    }

    constructor(public session: string, public timestamp: number, public cameraId: UID, public blob: Blob, public sliceIndex: number, mergeStatus?: MergeStatus, id?: string) {
        this.id = id || `${session}_${timestamp}_${sliceIndex}`;
        this.mergeStatus = mergeStatus || "not-merged";
    }
}

export class IDBVideo {
    static parseFromObject(source: any): IDBVideo {
        return new IDBVideo(source.id, source.session, source.databytes, source.type, source.cameraId, source.createdAt, source.uploadStatus);
    }
    createdAt: Date = new Date();
    uploadStatus?: "not-uploaded" | "uploaded" | "upload-error" = "not-uploaded";

    constructor(public id: string, public session: string, public databytes: Blob, public type: string, public cameraId: number | string, createdAt?: Date, uploadStatus?: "not-uploaded" | "uploaded" | "upload-error") {
        if (createdAt) this.createdAt = createdAt;
        if (uploadStatus) this.uploadStatus = uploadStatus;
    }

    public setUploaded() {
        this.uploadStatus = "uploaded";
    }

    public setUploadError() {
        this.uploadStatus = "upload-error";
    }

    resetUploadStatus(): void {
        this.uploadStatus = "not-uploaded";
    }

    public get isUploaded() {
        return this.uploadStatus === "uploaded";
    }
}

declare module "vue/types/vue" {
    interface Vue {
        $dbService: IndexedDbService;
    }
}
