










import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Component({})
export default class CustomAlert extends Vue {
  @Prop() title: string;
  @Prop() body: string;
  @Prop({default: false}) showButton: boolean;
  shown: boolean = false;

  public show() {
    this.shown = true;
  }

  public hide() {
    this.shown = false;
  }
}
