



















import { CameraType } from "@/zero/utilities/camera-type";
import Camera from "@/views/Camera.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CustomButton from "../components/CustomButton.vue";

@Component({
  components: { CustomButton }
})
export default class SellerButtons extends Vue {
  camerasAvailable: number[] = [];
  camera1available: boolean = false;
  camera2available: boolean = false;
  camera3available: boolean = false;
  camera4available: boolean = false;
  @Prop() visible: boolean;
  @Prop() activeCamera: number;
  @Prop() sharingScreen: boolean;

  /**
   *
   */
  constructor() {
    super();
  }

  mounted() {
    this.$camerasTracker.$on("camera-available", (camera: CameraType | number) => this.addAvailableCamera(camera));
    this.$camerasTracker.$on("camera-left", (camera: CameraType | number) => this.removeCamera(camera));
    this.$camerasTracker.availableCameras.forEach(tc => this.addAvailableCamera(tc.camera));
  }

  removeCamera(camera: number) {
    if (!this.camerasAvailable) return;
    this.camerasAvailable.includes(camera) && this.camerasAvailable.splice(this.camerasAvailable.indexOf(camera), 1);
  }

  addAvailableCamera(camera: number) {
    if (!this.camerasAvailable) return;
    !this.camerasAvailable.includes(camera) && this.camerasAvailable.push(camera) && this.camerasAvailable.sort();
  }

  @Watch("camerasAvailable", { deep: true, immediate: true })
  onCamerasAvailableChange() {
    if (!this.camerasAvailable) return;
    this.camera1available = this.camerasAvailable.includes(CameraType.DRAW_ON_SNEAKERS);
    this.camera2available = this.camerasAvailable.includes(CameraType.PUNCHING_MACHINE);
    this.camera3available = this.camerasAvailable.includes(CameraType.SERIGRAPY);
    this.camera4available = this.camerasAvailable.includes(CameraType.GRINDER);
  }

  changeCamera(cameraUid) {
    this.$emit("change-camera", cameraUid);
  }

  shareScreen() {
    this.$emit("share-screen");
  }

  public get isRealMode(): boolean {
    return process.env.VUE_APP_UIMODE == "real";
  }
}
