import jwtDecode, { JwtPayload } from "jwt-decode";
import Vue from "vue";
import { PluginFunction } from "vue";

declare module "vue/types/vue" {
  interface Vue {
    $gooseapi: GooseAPIClient
  }
}
export default class GooseAPIClient extends Vue {
  public static install: PluginFunction<GooseAPIClientOptions> = (__instance, options) => {
    __instance.prototype.$gooseapi = new GooseAPIClient(options);
  };
  baseUrl: string;

  constructor(options: GooseAPIClientOptions) {
    super();

    this.baseUrl = options.baseUrl;
  }

  async sendCallStats(data: {
    callEndedAtUtc: Date;
    callStartedAtUtc: Date;
    channel: string;
    outgointAvailableBandwidth: number;
    receiveBitrate: number;
    roundTripTime: number;
    sendBitrate: number;
    uid: import("agora-rtc-sdk-ng").UID;
    userAgent: string;
    collectionTime: 'AfterCallStart' | 'AfterCallEnd'
  }) {
    try {
      const userAgent = navigator.userAgent;
      const response = await fetch(`${this.baseUrl}/call-stats`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      });
      return response.ok;
    } catch (error) {
      console.error('An error occurred reporting call stats:\n' + JSON.stringify(error, null, 2));
    }
    return false;
  }

  async reportDeviceIssue(issueType: string, issueDescription: string, status: 'OK' | 'KO', duration: number): Promise<boolean> {
    try {
      const userAgent = navigator.userAgent;
      const response = await fetch(`${this.baseUrl}/customer-devices-issues`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userAgent,
          issueType,
          issueDescription,
          status,
          duration
        })
      });
      return response.ok;
    } catch (error) {
      console.error('An error occurred reporting customer device issue:\n' + JSON.stringify(error, null, 2));
    }
    return false;
  }

  async closeCall(slug: string): Promise<CloseCallResult> {
    const response = await fetch(`${this.baseUrl}/calls/${slug}/close`, {
      method: "put",
      headers: this.getAuthorizedRequestHeaders()
    });
    const result = await (response.json() as Promise<CloseCallResult>);
    return {
      success: response.ok,
      ...result
    };
  }

  async prepareRecordingSessionUpload(recordingSession: string, recordingsFiles: string[], booth: string, email: string) {
    const url = `${this.baseUrl}/recording-sessions`;
    const response = await fetch(url, {
      method: 'POST',
      headers: this.getAuthorizedRequestHeaders(),
      body: JSON.stringify({
        recordingSession,
        recordingsFiles,
        booth,
        email
      })
    }).catch(err => {
      return new Response(null, { status: 500, statusText: 'Error creating new upload session on server: ' + (err || 'unknown error') });
    });

    if (response.ok) {
      return true;
    }
    console.error(`POST to ${url} Failed with status code ${response.status} and message ${response.statusText}`);

    return false;
  }

  async createCall(booth: string, startCallAt: Date) {
    const response = await fetch(this.baseUrl + "/calls", {
      method: "post",
      headers: this.getAuthorizedRequestHeaders(),
      body: JSON.stringify({
        booth,
        startCallAt
      })
    });
    const result = await (response.json() as Promise<CreateCallResult>);
    return {
      success: response.ok,
      ...result
    };
  }

  async occupyBooth(booth: string, role: string): Promise<{ occupied: boolean; message?: string }> {
    const response = await fetch(this.baseUrl + `/booths/${booth}/${role === "seller" ? "occupy" : "occupy-as-assistant"}`, {
      method: "put",
      headers: this.getAuthorizedRequestHeaders()
    });
    if (response.ok) {
      return { occupied: true };
    }
    return { ...(await response.json()), occupied: false };
  }

  async freeBooth(booth: string): Promise<{ occupied: boolean; message?: string }> {
    const response = await fetch(this.baseUrl + `/booths/${booth}/free`, {
      method: "put",
      headers: this.getAuthorizedRequestHeaders()
    });
    if (response.ok) {
      return { occupied: false };
    }
    return { ...(await response.json()), occupied: true };
  }

  getUserRole() {
    const authToken = this.getCurrentToken();
    if (!authToken) return null;
    const jwt = jwtDecode<JwtPayload>(authToken);
    if (jwt) {
      return jwt["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/role"];
    }
    return null;
  }

  getUsername() {
    const authToken = this.getCurrentToken();
    if (!authToken) return null;
    const jwt = jwtDecode<JwtPayload>(authToken);
    if (jwt) {
      return jwt["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
    }
    return null;
  }

  getBoothLocale() {
    const jwt = this.getDecodedToken();
    if (jwt) {
      return jwt["booth-locale"];
    }
    return null;
  }
  getDecodedToken() {
    const authToken = this.getCurrentToken();
    if (!authToken) return null;
    const jwt = jwtDecode<JwtPayload>(authToken);
    return jwt;
  }

  async getBooths(onlyFree?: boolean): Promise<Booth[]> {
    const response = await fetch(this.baseUrl + `/booths?${onlyFree ? "filter.free=true" : ""}`, {
      method: "get",
      headers: this.getAuthorizedRequestHeaders()
    });
    if (response.ok) {
      return await (response.json() as Promise<Booth[]>);
    }
    return [];
  }

  async refreshToken() {
    const response = await fetch(this.baseUrl + `/auth/refresh`, {
      method: "POST",
      headers: this.getAuthorizedRequestHeaders()
    });
    if (response.ok) {
      const newToken = await response.text();
      if (newToken)
        localStorage.setItem("sales-booth-auth", newToken);
    }
    return this.getCurrentToken();
  }

  async login(username: string, password: string, booth: string): Promise<LoginResult> {
    const response = await fetch(this.baseUrl + "/auth", {
      method: "post",
      body: JSON.stringify({
        username,
        password,
        booth
      }),
      headers: {
        "Content-Type": "application/json"
      }
    });

    const result: LoginResult = await response.json();

    localStorage.setItem("sales-booth-auth", `${result.token}`);
    let decodedToken: JwtPayload;
    try {
      decodedToken = jwtDecode<JwtPayload>(result.token)
    } catch (error) {
      //
    }
    return {
      ...result,
      decodedToken,
      success: response.ok
    };
  }

  async getAgoraToken(channelName: string, uid: number, authenticate: boolean = true, expiration?: Date): Promise<string> {
    const authToken = this.getCurrentToken();
    const url = this.baseUrl + (authenticate ? "/AgoraToken" : '/calls/agoraToken')
    const response = await fetch(url, {
      body: JSON.stringify({
        channelName,
        uid,
        expiration
      }),
      method: "post",
      headers: authenticate ? this.getAuthorizedRequestHeaders() : { 'Content-Type': 'application/json' }
    });
    if (response.ok) {
      if (authenticate)
        return await response.text();
      else {
        return (await response.json()).agoraToken;
      }
    }
  }

  getCurrentToken() {
    return getCurrentAuthToken();
  }

  getAuthorizedRequestHeaders(): HeadersInit {
    const authToken = getCurrentAuthToken();
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`
    };
  }
}

export function getCurrentAuthToken() {
  return localStorage.getItem("sales-booth-auth");
}

export interface GooseAPIClientOptions {
  baseUrl: string;
}

export interface LoginResult extends BaseResult {
  success: boolean;
  role?: string;
  token?: string;
  cameraId?: string;
  error?: string;
  startupBoothSlug?: string;
  decodedToken: JwtPayload;
}

export interface Booth {
  id: number;
  name: string;
  slug: string;
}

export interface CreateCallResult extends BaseResult {
  slug?: string;
}

export interface CloseCallResult extends BaseResult { }

export interface BaseResult {
  success: boolean;
  message?: string;
}
