














import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import LandingPage from "@/components/buyer/LandingPage.vue";
import ExitPage from "@/components/buyer/ExitPage.vue";
@Component({
  components: {
    LandingPage,
    ExitPage
  }
})
export default class ByePage extends Vue {
  @Prop() callSlug: string;

  mounted() {
    this.$logSend.disable();
    setTimeout(() => {
      this.showModal();
    }, 1000);
  }

  returnToCall() {
    if (this.callSlug) {
      this.$router.replace(`/c/${this.callSlug}`).catch(() => {});
    }
  }

  visitSite() {
    window.location.href = "https://www.goldengoose.com/";
  }

  showModal() {
    if(this.callSlug) this.$bvModal.show('exit-modal');
  }
}
