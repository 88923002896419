







import { OnlineSession } from "@/models/online-session";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import CustomButton from "@/components/CustomButton.vue";
import Counter from "@/components/polaroid/Counter.vue";
import Flash from "@/components/polaroid/Flash.vue";

@Component({
  components: {
    CustomButton,
    Counter,
    Flash,
  },
})
export default class PolaroidButton extends Vue {
  @Prop() enabled: boolean;
  @Prop() recordingSession: string;
  @Prop() onlineSession: OnlineSession;
  @Prop() videoElement: HTMLVideoElement;
  @Prop({type: String, default: 'Send picture'}) buttonText : string;
  @Prop({type: String, default: 'online'}) mode: 'online' | 'instore';

  counter: boolean = false;
  enableFlash: boolean = false;

  /**
   *
   */
  constructor() {
    super();
    this.$root.$on("polaroid-picture-sent-error", () => {
      alert("Polaroid picture could not be sent");
    });
  }

  showCounter() {
    this.counter = true;
  }

  public get canClick(): boolean {
    return this.enabled && (this.mode == 'instore' || !!this.recordingSession && !!this.onlineSession?.customerUrl);
  }

  async sendPicture() {
    this.$root.$emit("polaroid-picture-sent-start");
    this.enableFlash = true;
    setTimeout(() => {
      this.enableFlash = false;
    }, 200);
    const file: Blob = await this.getPicture();
    this.$emit("polaroid-picture-taken");
    const sendResult = this.mode == 'instore' ? true : await this.$polaroid.sendPolaroid(
      this.recordingSession,
      this.onlineSession.boothSlug,
      this.onlineSession.customerUrl,
      file,
      this.onlineSession.apiPassword
    );
    if (sendResult) {
      this.$root.$emit("polaroid-picture-sent-ok");
    } else {
      this.$root.$emit("polaroid-picture-sent-error");
    }
  }

  endCounter(value) {
    if (value) {
      this.counter = false;
      this.sendPicture();
    }
  }

  getPicture(): Blob | PromiseLike<Blob> {
    // TODO mettere qui la logica per fare la foto?
    return new Promise<Blob>((resolve, reject) => {
      let w, h, ratio, lW, lH, logoRatio;

      ratio = this.videoElement.videoWidth / this.videoElement.videoHeight;
      w = this.videoElement.videoWidth;
      h = Math.floor(w / ratio);

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = w;
      canvas.height = h;
      canvas.style.position = "absolute";
      canvas.style.top = `${-2 * h}px`;
      canvas.style.left = `${-2 * w}px`;

      context.fillRect(0, 0, w, h);
      context.drawImage(this.videoElement, 0, 0, w, h);

      //  const image = document.getElementById("logo");
      //  logoRatio = image.offsetWidth / image.offsetHeight;
      //  lW = this.videoElement.videoWidth * 0.1;
      //  lH = Math.floor(lW / logoRatio);
      //  context.drawImage(image, w - (lW + 10), h - (lH + 10), lW, lH);

      canvas.toBlob(
        (blob) => {
          canvas.remove();
          resolve(blob);
        },
        "image/jpg",
        0.6
      );
    });
  }
}
