










import { OnlineSession } from "@/models/online-session";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
@Component({
  data() {
    return {};
  }
})
export default class Polaroid extends Vue {
  @Prop() callSlug: string;
  @Prop() onlineSession: OnlineSession;
  showMe: boolean = false;
  photoshot: string = null;

  get polaroidName() {
    return "polaroid-" + Number(new Date()).toString();
  }

  public get hasPhotoShot(): boolean {
    return !!this.photoshot;
  }

  async setPolaroid(callBack){
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const articleImg = new Image();
    articleImg.src = this.photoshot;
    const img = new Image();
    img.src = require(`@/assets/images/pola_frame_wide.png`);
    img.onload = function() {
      canvas.width = 1395;
      canvas.height = 1009;
      ctx.fillStyle = "#ffffff";
      ctx.fillRect(0,0,canvas.width,canvas.height);
      ctx.drawImage(articleImg, 64, 89, 1280, 720);
      ctx.drawImage(img, 0, 0);
      return callBack(canvas);
    };
  }

  public get imageStyle(): string {
    return `backgroundImage: url(${this.photoshot});`;
  }

  downloadAndDisappear(e: any) {
    if(typeof(e) === 'undefined' || e == null) return;
    if (this.isTouchEvent(e)) e.preventDefault();
    const a = document.createElement("a");
    this.setPolaroid((data) => {
      a.href = data.toDataURL("image/jpeg");
      a.download = this.polaroidName;
      a.click();
      setTimeout(() => {
        this.photoshot = null;
        this.showMe = false;
      }, 250);
    });
  }

  isTouchEvent(e: any) {
    return !!e && "pointerType" in e && e.pointerType === "touch";
  }

  constructor() {
    super();
    const vm = this;
    this.$signalr.connection.on("SessionPhotoshot", (recordingSession: string) => {
      this.showPolaroid.call(this, recordingSession, this.onlineSession.apiPassword);
    });
  }

  async showPolaroid(session: string, apiPassword: string) {
    try {
      this.photoshot = await this.$polaroid.getPolaroid(session, apiPassword);
      // setTimeout(function() {
      this.showMe = true;
      // }, 500);
    } catch (error) {
      console.error("Could not get photoshot (1)" + JSON.stringify(error));
    }
  }
}
