



import Vue from "vue";
import Component from "vue-class-component";
import CustomSelect from "./CustomSelect.vue";

@Component({
  name: "AudioOutput",
  components: {
    CustomSelect
  }
})
export default class AudioOutput extends Vue {
  audioOutputs: { deviceId: string; name: string }[] = [];
  _devices: MediaDeviceInfo[] = [];

  public get defaultAudioOutput(): { deviceId: string; name: string } {
    return this.audioOutputs.length > 0 ? this.audioOutputs[0] : null;
  }

  async mounted() {
    const availableDevices = await this.$agoraClient.getDevices("audiooutput");
    this._devices = availableDevices.filter((value, index, self) => self.findIndex(d => d.groupId === value.groupId) === index);
    this.audioOutputs = this._devices.map(mdi => {
      return { name: mdi.label, deviceId: mdi.deviceId };
    });
  }

  settingSelected(setting: { deviceId: string; name: string }) {
    const device = this._devices.find(d => d.deviceId === setting.deviceId);
    this.$emit("deviceSelected", device);
  }
}
