



import Vue from "vue";
import Component from "vue-class-component";
import CustomSelect from "./CustomSelect.vue";
@Component({
  name: "AudioInput",
  components: {
    CustomSelect
  }
})
export default class AudioInput extends Vue {
  audioInputs: { deviceId: string; name: string }[] = [];
  _devices: MediaDeviceInfo[] = [];
  public get defaultAudioInput(): { deviceId: string; name: string } {
    return this.audioInputs.length > 0 ? this.audioInputs[0] : null;
  }

  async mounted() {
    const availableDevices = await this.$agoraClient.getDevices("audioinput");
    this._devices = availableDevices.filter((value, index, self) => self.findIndex(d => d.groupId === value.groupId) === index);
    this.audioInputs = this._devices.map(mdi => {
      return { name: mdi.label, deviceId: mdi.deviceId };
    });
  }

  settingSelected(setting: { deviceId: string; name: string }) {
    const device = this._devices.find(d => d.deviceId === setting.deviceId);
    this.$emit("deviceSelected", device);
  }
}
