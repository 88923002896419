








import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class ScreenSaver extends Vue {
  @Prop({ default: 60 }) time: number;

  showScreenSaver: boolean = false;
  timeoutScreenSaver: any = null;
  
  mounted() {
    this.enableScreenSaver('init');
  }

  beforeDestroy() {
    clearTimeout(this.timeoutScreenSaver);
  }

  enableScreenSaver(e?: MouseEvent | TouchEvent | undefined | 'init') {
    if(typeof(e) === 'undefined' || e == null) return;
    if (e != 'init' && this.isTouchEvent(e)) (e as TouchEvent).preventDefault();

    console.log("Screensaver enabled, starting timeout at " + this.time + " seconds");
    this.showScreenSaver = false;
    this.timeoutScreenSaver = setTimeout(() => {
      console.log("Screensaver timeout happened, showing screensaver");
      this.showScreenSaver = true;
    }, this.time * 1000);
  }

  isTouchEvent(e: any) {
    return !!e && "pointerType" in e && e.pointerType === "touch";
  }
}
