import { UID } from "agora-rtc-sdk-ng";

export enum CameraType {
    SNEAKER_MAKER = 0,
    DRAW_ON_SNEAKERS = 10001, 
    PUNCHING_MACHINE = 10002,
    SERIGRAPY = 10003,
    GRINDER = 10004,
}

export const allCameras = [CameraType.DRAW_ON_SNEAKERS, CameraType.PUNCHING_MACHINE, CameraType.SERIGRAPY, CameraType.GRINDER]

export function isCamera(id: string | UID | number) {
    return +id == CameraType.DRAW_ON_SNEAKERS ||
    +id == CameraType.PUNCHING_MACHINE ||
    +id == CameraType.SERIGRAPY ||
    +id == CameraType.GRINDER;
}