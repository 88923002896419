import Vue, { PluginFunction } from "vue";

declare module "vue/types/vue" {
   interface Vue {
     $bluetooth: BluetoothService
   }
 }
export default class BluetoothService extends Vue {
   requestReloadForNewBluetoothDevice() {
     this.$jsBridge.requestReloadForNewBluetoothDevice();
   }

   public static install: PluginFunction<void> = (__instance) => {
     __instance.prototype.$bluetooth = new BluetoothService();
   };
 
   constructor() {
      super();
      const vm = this.$root;
      window.addEventListener('new-bluetooth-device', function(arg1: CustomEvent) {
         console.log('BluetoothService detected new bluetooth device');
         vm.$emit('new-bluetooth-device', [arg1.detail])
      });
      window.addEventListener('bluetooth-device-removed', function(arg1: CustomEvent) {
         console.log('BluetoothService detected a bluetooth device has been removed');
         vm.$emit('bluetooth-device-removed', [arg1.detail])
      });
      
   }
}