





































import { Booth } from "@/zero/services/goose-apis";
import Vue from "vue";
import CustomSelect from "../components/CustomSelect.vue";

export default Vue.extend({
  name: "Home",
  components: {
    CustomSelect
  },
  data() {
    return {
      username: "",
      password: "",
      booth: "",
      freebooths: [],
      loggedIn: false,
      cameraId: "",
      role: ""
    };
  },
  methods: {
    preventNav(event: Event) {
      event.preventDefault();
    },

    async login() {
      if (!this.$refs["loginForm"].reportValidity()) {
        return;
      }
      const stdErrMsg = "Could not login, an error has occurred";
      try {
        const { success, role, cameraId, message, startupBoothSlug, decodedToken } = await this.$gooseapi.login(this.$data.username, this.$data.password, this.$data.booth.slug);
        if (success) {
          localStorage.setItem("SM-provisioning-user", `${this.$data.username}`);

          this.$data.freebooths = await this.getBooths();
          this.$data.cameraId = cameraId;
          this.$data.role = role;
          this.$data.locale = decodedToken["booth-locale"];
          if (startupBoothSlug) {
            this.enterBooth(startupBoothSlug);
            return;
          } else {
            this.$data.loggedIn = true;
            return;
          }
        } else {
          alert(message || stdErrMsg);
        }
      } catch (error) {
        alert(stdErrMsg);
      }
      this.$data.loggedIn = false;
    },

    async getBooths(onlyFree?: boolean): Promise<Booth[]> {
      return await this.$gooseapi.getBooths(onlyFree);
    },

    enterBooth(boothSlug?: string | MouseEvent) {
      if (typeof boothSlug === "string") {
        this.$data.booth = this.$data.freebooths.find(b => b.slug === boothSlug);
      }
      if (this.$data.booth) {
        if (this.$gooseapi.getUserRole() === "seller") {
          const userName = this.$gooseapi.getUsername();
          const occupiedByOthers =
            this.$data.booth.occupiedBy &&
            (this.$data.booth.occupiedBy != userName ? this.$data.booth.occupiedBy : "another user with the same credentials you just used (" + userName + ")");

          const confirmMessage =
            `The set ${this.$data.booth.name} is already occupied by ${occupiedByOthers}.\n\n` +
            `If you continue, current user will be logged out.\n\n` +
            `Do you wish to continue?`;

          if (occupiedByOthers && !confirm(confirmMessage)) {
            return;
          }
        }
        location.href = 
          (this.$data.role == 'seller' ? `/${this.$data.locale}` : '') + 
          `/${this.$data.role}/${this.$data.booth.slug}` + 
          (this.$data.cameraId ? `/${this.$data.cameraId}` : "");
      }
    },
    boothSelected(item) {
      this.$data.booth = item;
    }
  },
  async mounted() {},
  beforeMount() {
    window.addEventListener("beforeunload", (ev: BeforeUnloadEvent) => this.preventNav(ev));
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  }
});
