<template>
  <div
    class="counter"
    :class="{ 'counter--small': small }"
    v-if="show && clean"
  >
    <div class="counter__container" v-if="count > 0">
      <span
        class="counter__number"
        :style="{ animationIterationCount: number }"
        >{{ count }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Counter",
  data() {
    return {
      count: null,
      loop: null,
      clean: true
    };
  },
  props: {
    number: Number,
    show: Boolean,
    small: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    counter() {
      this.count = this.count - 1;
      if (this.count < 1) {
        this.count = null;
        console.log(`interval stopping at exactly `, Number(new Date()));

        window.clearInterval(this.loop);
        window.setTimeout(this.exitCounter, 100);
      }
    },
    exitCounter() {
      this.clean = true;
      this.count = this.number;
      this.$emit("exit", true);
    }
  },
  watch: {
    show: {
      immediate: true,
      handler(val, oldVal) {
        if (val && !oldVal) {
          console.log(`interval starting at exactly `, Number(new Date()));
          this.loop = window.setInterval(this.counter, 1000);
        }
      }
    }
  },
  mounted() {
    this.count = this.number;
  }
};
</script>

<style lang="scss" scoped>
.counter {
  $class-counter: &;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;

  &__number {
    font-family: 'Simplon_monomedium', monospace;
    display: block;
    color: white;
    font-size: 25vw;
    animation: counter 1s;
  }

  &--small {
    #{$class-counter}__container {
      display: flex;
      position: absolute;
      height: 12vw;
      width: 12vw;
      bottom: 3vw;
      left: 50%;
      justify-content: center;
      align-items: center;
      transform: translateX(-50%);
    }
    #{$class-counter}__number {
      font-size: 2rem;
    }
  }
}

@keyframes counter {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(2);
  }
}
</style>
