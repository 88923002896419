import { PluginFunction } from "vue";
import { Session } from "../file-worker/uploader";

export default class UploadService {
   public static install: PluginFunction<UploadAPIClientOptions> = (__instance, options) => {
      __instance.prototype.$uploadApi = new UploadService(options);
   }

   constructor(options: UploadAPIClientOptions) {
      this.baseUrl = options.baseUrl;
   }

   baseUrl: string

   async checkSessionsForUploadability(sessions: string[]): Promise<string[]> {
      const url = `${this.baseUrl}/check-sessions-for-uploadability`;
      const response = await fetch(url, {
         method: 'POST',
         headers: {
            'content-type': 'application/json'
         },
         body: JSON.stringify({
            sessions: sessions
         })
      }).catch(err => {
         return new Response(null, { status: 500, statusText: 'Error checking for sessions uploadability on server: ' + (err || 'unknown error') });
      });

      if (response.ok) {
         return await response.json();
      }
      console.error(`POST to ${url} Failed with status code ${response.status} and message ${response.statusText}`);
      return [];
   }

   async prepareRecordingSessionUpload(request: PrepareRecordingSessionUploadRequest): Promise<boolean> {
      const url = `${this.baseUrl}/prepare-session-upload`;
      const body = JSON.stringify({
         appId: 'SNEAKER-MAKER',
         ...request
      });
      const response = await fetch(url, {
         method: 'POST',
         headers: {
            'content-type': 'application/json'
         },
         body
      }).catch(err => {
         return new Response(null, { status: 500, statusText: 'Error creating new upload session on server: ' + (err || 'unknown error') });
      });

      if (response.ok) {
         return true;
      }
      console.error(`POST to ${url} Failed with status code ${response.status} and message ${response.statusText}`);

      return false;

   }

}

export class PrepareRecordingSessionUploadRequest {
   constructor(
      public recordingSession: string,
      public recordingsFiles: string[],
      public booth: string,
      public email: string,
      public nfcCode: string[],
      public startedAt: Date,
      public endedAt: Date,
      public sessionLocale: string = 'en',
      public mode: 'Online' | 'InStore'
   ) {

   }
}

export class UploadAPIClientOptions {
   baseUrl: string;

}


declare module "vue/types/vue" {
   interface Vue {
      $uploadApi: UploadService
   }
}

