









































































import BaseVue from "@/zero/utilities/base-vue";
import AgoraRTC, { IAgoraRTCRemoteUser, ILocalVideoTrack, UID } from "agora-rtc-sdk-ng";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import ControlPanel from "../components/ControlPanel.vue";
import SellerButtons from "../components/SellerButtons.vue";
import CustomOptions from "../components/CustomOptions.vue";
import CustomButton from "../components/CustomButton.vue";
import RtcStatsPanel from "../components/rtc-stats-panel.vue";
import Modal from "../components/Modal.vue";
import CustomSelect from "../components/CustomSelect.vue";
import { start } from "@/zero/services/file.worker";
import { OnlineSession } from "@/models/online-session";
import SwitchCamera from "@/components/switch-camera/SwitchCamera.vue";
import fetchJson from "@/zero/utilities/fetch-json";
import LandingPage from "@/components/buyer/LandingPage.vue";
import Polaroid from "@/components/polaroid/Polaroid.vue";
import GenericCountdown from "@/components/GenericCountdown.vue";
import BuyerDeviceChecks from "@/components/buyer-device-checks/BuyerDeviceChecks.vue";
import router from "@/router";
import LogSend from "@/zero/services/logSend";
@Component({
  props: {
    booth: {
      type: String,
      default: "milano1",
    },
  },
  components: {
    GenericCountdown,
    SellerButtons,
    CustomOptions,
    ControlPanel,
    CustomButton,
    RtcStatsPanel,
    Modal,
    CustomSelect,
    LandingPage,
    Polaroid,
    SwitchCamera,
    BuyerDeviceChecks,
  },
  data() {
    return {
      startCallError: null,
    };
  },
})
export default class Buyer extends BaseVue {
  uid = 3333;
  waitingForSeller = true;
  activeCameraUser: IAgoraRTCRemoteUser;
  isCameraActive = false;
  callSlug: string = "";
  needsFirstInteraction = true;
  existingCallInfo: OnlineSession = null;
  showLanding = true;
  startingCall: boolean = false;
  currentCameraIndex: number = -1;
  sellerUser: IAgoraRTCRemoteUser;
  subscribedSellerAudio: boolean;
  canStartCall = true;
  onlineSessionStartedAt: Date;
  self: Buyer;
  camerasCount: number = 1;
  /**
   *
   */

  constructor() {
    super();
    console.log("Buyer vue constructor");
    this.$logSend.addToContext("uid", this.uid);
    this.$logSend.enable();
    this.$signalr.onChangeCameraRequest(this, this.onChangeCameraRequest);
    this.$signalr.onCallClosed(this, this.onCallClosed);
    this.$signalr.onJoinedBoothControl(this, (...args) => {
      this.onJoinedBoothControl(this, ...args);
    });
    console.log("End Buyer vue constructor");

    this.$root.$on("buyer-device-checks-start", () => {
      const btn = this.$refs.btnStartCall as HTMLButtonElement;
      if (typeof btn === "undefined") return;
      btn.setAttribute("disabled", "");
      btn.innerText = this.$t("BUYER_LANDING_ONLINE.MODAL_BUTTON_PLEASE_WAIT").toString();
      const callAction = this.$refs.boxCallAction as HTMLDivElement;
      callAction.style.display = "block";
    });

    this.$root.$on("buyer-device-checks-completed-ok", () => {
      const btn = this.$refs.btnStartCall as HTMLButtonElement;
      if (typeof btn === "undefined") return;
      btn.removeAttribute("disabled");
      btn.innerText = this.$t("BUYER_LANDING_ONLINE.MODAL_BUTTON").toString();
      const callAction = this.$refs.boxCallAction as HTMLDivElement;
      callAction.style.display = "block";
    });

    this.$root.$on("buyer-device-checks-completed-ko", () => {
      const btn = this.$refs.btnStartCall as HTMLButtonElement;
      if (typeof btn === "undefined") return;
      btn.removeAttribute("disabled");
      btn.innerText = this.$t("BUYER_LANDING_ONLINE.MODAL_BUTTON_CHECKS_KO").toString();
      const callAction = this.$refs.boxCallAction as HTMLDivElement;
      callAction.style.display = "none";
    });

  }

  public get hasInitCallError(): boolean {
    return this.$data.startCallError !== null;
  }

  async onJoinedBoothControl(sender: Buyer, connectionId: string, booth: string, uid: UID, role: string) {
    console.debug(`User ${uid} joined booth control channel or refreshed join information`);
    if (uid === 3333) {
      this.$router.push(`/c/bye/${this.callSlug}`).catch((err) => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (err.name != "NavigationDuplicated") {
          // But print any other errors to the console
          console.error(err);
        }
      });
    }
  }

  quitCall() {
    this.sendCallStats(this.onlineSessionStartedAt, new Date(), this.channel, this.uid, "AfterCallEnd");
    this.$router.push(`/c/bye/${this.callSlug}`).catch((err) => {
      // Ignore the vuex err regarding  navigating to the page they are already on.
      if (err.name != "NavigationDuplicated") {
        // But print any other errors to the console
        console.error(err);
      }
    });
  }

  async onCallClosed(cameraSlug?: string) {
    if (cameraSlug == this.callSlug) {
      this.sendCallStats(this.onlineSessionStartedAt, new Date(), this.channel, this.uid, "AfterCallEnd");
      this.$logSend.disable();
      this.$router.replace("/c/bye");
    }
  }

  async onChangeCameraRequest(cameraId: number) {
    const oldActiveCameraUser = this.activeCameraUser;
    if (cameraId > 0) {
      console.log("waiting for camera " + cameraId + " to being publishing");
      const ru = await this.cameraIsPublishing(cameraId);
      console.log("subscribing stream");
      this.activeCameraUser = ru;
      await this.$agoraClient.subscribe(ru, true, true, "camera_stream", ru.uid === 4444 ? "contain" : "cover");
      this.isCameraActive = true;
    } else {
      this.activeCameraUser = null;
      this.isCameraActive = false;
    }
    if (oldActiveCameraUser) {
      await this.$agoraClient.unsubscribe(oldActiveCameraUser, true, true);
    }
  }

  async mounted() {

    console.log("mounting");
    await this.initPromise;
    try {
      this.existingCallInfo = JSON.parse(localStorage.getItem("callInfo") || "{}", (key, value) => {
        if (typeof value === "string" && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(value)) return new Date(value);
        return value;
      }) as OnlineSession;
      this.callSlug = this.$route.params.callSlug;
      const callBooth = this.existingCallInfo.boothSlug;
      this.$logSend.addToContext("booth", callBooth);
      this.$logSend.addToContext("session", this.existingCallInfo.recordingSessionId);
      console.log(`Joining channel uid: ${this.uid} call slug: ${this.callSlug} token: ${this.existingCallInfo.customerAgoraToken}`);
      await this.joinBoothChannel(this.uid, this.callSlug, this.existingCallInfo.customerAgoraToken);
    } catch (e) {
      console.error(e);
      alert("There was an error initializing the page, please refresh to retry");
    }
    console.log("end mounting");
  }

  async startCall() {
    this.$data.startCallError = null;
    this.startingCall = true;
    this.$agoraClient.removeAllListeners("stream-published");
    this.$agoraClient.on("stream-published", this, (user: IAgoraRTCRemoteUser) => {
      this.onStreamPublished(user);
    });
    if (this.existingCallInfo.boothSlug) {
      await this.joinBoothControl(this.uid, this.existingCallInfo.boothSlug, "buyer");
    }
    console.log("publish streams");
    const cameraTrack = await this.getCameraTrack(null);
    this.localVideoTrack = cameraTrack;
    const audioTrack = await this.getAudioTrack(null);
    this.localAudioTrack = audioTrack;
    this.addPosterAttribute(
      "local_stream",
      "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg width='100' height='100' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Crect stroke-width='0' id='svg_1' height='100' width='100' y='0' x='0' stroke='%23000' fill='%23000000'/%3E%3C/g%3E%3C/svg%3E"
    );
    await this.playVideoTrackTo("local_stream");

    if (!this.subscribedSellerAudio && this.sellerUser) {
      await this.$agoraClient.subscribe(this.sellerUser, false, true);
    }
    console.log("finish startCall");
    this.needsFirstInteraction = false;
    this.showLanding = this.needsFirstInteraction;

    try {
      await this.publishTracks([cameraTrack, audioTrack]);
    } catch (error) {
      console.error("An error occurred during publish streams: " + JSON.stringify(error));
      if (typeof error === "string") this.$data.startCallError = error;
      else this.$data.startCallError = "GENERIC_ERROR";
      return;
    }

    const cameras = await this.$agoraClient.getCameras();
    this.camerasCount = cameras.length;
    const currentDeviceId = this.localVideoTrack.getMediaStreamTrack().getCapabilities().deviceId;
    const switcher = this.$refs.cameraSwitcher as SwitchCamera;
    if (switcher) switcher.currentIndex = cameras.findIndex((c) => c.deviceId === currentDeviceId);

    if (this.isAppleMobile()) {
      console.log("Apple mobile device detected, waiting 3250ms before toggling view track");
      await new Promise<void>((resolve) => window.setTimeout(() => resolve(), 3250));
      console.log("toggling videotrack off");
      await this.toggleVideo();
      console.log("toggling videotrack on");
      await this.toggleVideo();
      console.log("Waited for Apple to do it's things, hopefully it'll work");
    }

    const that = this;

    const containerElement = document.getElementById("camera_stream");
    if (containerElement) {
      const observer = new MutationObserver(() => {
        const videoEl = containerElement.querySelector(".agora_video_player") as HTMLElement;
        if (videoEl) {
          if (that.activeCameraUser.uid.toString() === "10001") {
            videoEl.classList.add("ultra-flip");
          }
        }
      });

      observer.observe(containerElement, { childList: true, subtree: true });
    }

    this.onlineSessionStartedAt = new Date();

    window.setTimeout(async () => await this.sendCallStats(this.onlineSessionStartedAt, null, this.channel, this.uid, "AfterCallStart"), 30000);
  }

  isAppleMobile() {
    const ua = navigator.userAgent;
    const osIsApple = !!ua.match(/Macintosh/) || !!ua.match(/iPhone/) || !!ua.match(/iPad/);
    const isSafari = !!ua.match(/Safari\//) && !ua.match(/Chrome/);
    const isTouch = "ontouchstart" in document.documentElement;
    return osIsApple && isSafari;
  }

  onStreamPublished(user: IAgoraRTCRemoteUser) {
    this.startingCall = false;
  }

  async onRemoteUserJoined(sender, user: IAgoraRTCRemoteUser) {
    if (user.uid === 1111) {
      this.waitingForSeller = false;
    }
    this.addRemoteUser(user.uid, user);
  }

  async tryGetCallBooth(): Promise<string | PromiseLike<string>> {
    try {
      const getCallResult = await fetchJson<OnlineSession>(
        `${process.env.VUE_APP_UPLOAD_API_URL ?? "https://localhost:5001/api"}/customer-online-sessions/${this.$route.params.callSlug}`
      );
      this.existingCallInfo = getCallResult;
      localStorage.setItem("callInfo", JSON.stringify(getCallResult));
      return getCallResult.boothSlug;
    } catch (error) {
      console.error(error);
    }
  }

  onRemoteUserLeft(sender, user: IAgoraRTCRemoteUser) {
    sender.removeRemoteUser(user.uid);
  }

  showModal() {
    this.$bvModal.show("my-modal");
  }

  async onRemoteUserPublished(sender: Buyer, user: IAgoraRTCRemoteUser) {
    console.log(`USER PUBLISHED ${user.uid}`);
    if (user.uid === 1111) {
      //this.showLanding = false;
      this.showModal();

      if (user.hasVideo && !this.existingCallInfo.boothSlug) {
        const callBooth: string = await this.tryGetCallBooth();
        await this.joinBoothControl(this.uid, this.existingCallInfo.boothSlug, "buyer");
      }
      await sender.$agoraClient.subscribe(user, true, !this.needsFirstInteraction, "vendor_stream");
      this.sellerUser = user;
      this.subscribedSellerAudio = !this.needsFirstInteraction;
      this.waitingForSeller = false;
    }
  }

  async onRemoteUserUnpublished(sender: Buyer, user: IAgoraRTCRemoteUser) {
    if (user.uid === 1111) {
      if (!user.hasAudio && !user.hasVideo) this.waitingForSeller = true;
    }
  }

  removeRemoteUser(uid: UID) {
    this.remoteUsers.delete(uid);
  }

  async onSwitchingCamera(newCamera: ILocalVideoTrack) {
    const oldTrack = this.localVideoTrack;
    await this.$agoraClient.unpublishTracks(oldTrack);
    let retry = 0;
    let error: any;
    do {
      try {
        await this.$agoraClient.publishTracks(newCamera);
        error = null;
        break;
      } catch (e) {
        error = e;
        if (++retry < 5) {
          await new Promise<void>((resolve) =>
            setTimeout(() => {
              resolve();
            }, 200)
          );
        }
      }
    } while (retry < 5);
    if (error) {
      console.error(error);
    }

    this.localVideoTrack = newCamera;
    this.addPosterAttribute(
      "local_stream",
      "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg width='100' height='100' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Crect stroke-width='0' id='svg_1' height='100' width='100' y='0' x='0' stroke='%23000' fill='%23000000'/%3E%3C/g%3E%3C/svg%3E"
    );
    await this.playVideoTrackTo("local_stream");
    this.$root.$emit("camera-switched");
    await oldTrack.stop();
    oldTrack.getMediaStreamTrack().stop();
  }
}
