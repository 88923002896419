






















import { OnlineSession } from "@/models/online-session";
import fetchJson from "@/zero/utilities/fetch-json";
import Vue from "vue";
import Component from "vue-class-component";

export type DialogResult = OnlineSession | "dialogCancelled";

@Component({})
export default class OnlineExperienceSetup extends Vue {
  status: "off" | "initial" | "waiting" | "closed-session" = "off";
  sessionCode: string = "";
  resolver: any = null;
  rejecter: any = null;
  onlineSession: OnlineSession = new OnlineSession();
  error: any = null;

  mounted() {
    this.status = "off";
  }

  show(): Promise<DialogResult> {
    if (this.status != "off") return Promise.reject("An istance of OnlineExperienceSetup is already running");
    this.sessionCode = "";
    this.error = "";
    this.status = "initial";
    return new Promise<DialogResult>((resolve, reject) => {
      this.resolver = resolve;
      this.rejecter = reject;
    });
  }

  closeDialog(result?: DialogResult) {
    this.status = "off";
    if (this.resolver) this.resolver(result);
  }

  cancel() {
    console.log("OnlineExperienceSetup dialog cancelled");
    this.closeDialog("dialogCancelled");
  }

  async checkSessionCode() {
    this.status = "waiting";
    if (this.sessionCode) {
      // to be replaced by api call
      try {
        this.onlineSession = await fetchJson<OnlineSession>(`${process.env.VUE_APP_UPLOAD_API_URL ?? "https://localhost:5001/api"}/online-sessions/code/${this.sessionCode}`, {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            boothSlug: this.$route.params.booth,
          }),
        });
        if (!this.onlineSession.isClosed) {
          this.closeDialog(this.onlineSession);
          return;
        }
      } catch (error) {
        this.status = "initial";
        if (error.message === "not-found") {
          this.error = "Error: could not find any Sneakers Maker Online session with code " + this.sessionCode.toUpperCase();
        } else {
          this.error = `Error: an internal error occurred (${error})`;
        }
        return;
      }
    }
    this.status = "closed-session";
  }
}
