import { TestResult } from "./TestResult";


export interface IDeviceTest {

   name: string;

   result: TestResult;

   status: 'idle' | 'running' | 'finished';

   succeeded: boolean;

   failedInfo: string;

   failureTitle: string;

   suggestions: string[];

   run(log?: boolean, forceKO?: boolean, simulateSlowiness?: number): Promise<TestResult>;

   clear(): void;
}

export abstract class DeviceTestBase implements IDeviceTest {
   private _result: TestResult;
   private _status: "idle" | "running" | "finished" = 'idle';
   private _suggestions: string[] = [];

   get result(): TestResult {
      return this._result;
   }
   get status(): "idle" | "running" | "finished" {
      return this._status;
   }

   get suggestions(): string[] {
      return this._suggestions;
   }

   abstract get failedInfo(): string;

   abstract get failureTitle(): string;

   public get succeeded(): boolean {
      return this._status == 'finished' && this._result && this._result.succeeded;
   }


   constructor(public name: string) { }

   clear(): void {
      this.clearSuggestions();
      this._status = 'idle';
      this._result = null;
   }

   setSuggestions(...suggestions: string[]) {
      this.clearSuggestions();
      this.addSuggestions(...suggestions);
   }

   addSuggestions(...suggestions: string[]) {
      this._suggestions.push(...suggestions);
   }

   clearSuggestions() {
      this._suggestions = [];
   }

   async run(log: boolean = true, forceKO: boolean = false, simulateSlowiness?: number): Promise<TestResult> {
      if(log)
      console.log('starting running test ' + this.name);
      this._status = 'running'
      if(simulateSlowiness && simulateSlowiness > 0) {
         await new Promise<void>(resolve => setTimeout(() => resolve(), simulateSlowiness));
      }
      this._result = await this.runLogic(forceKO);
      this._status = 'finished'
      if(log)
      console.log('finished running test ' + this.name);

      return this._result;
   }

   protected abstract runLogic(forceKO?: boolean): Promise<TestResult>;
}

