
export abstract class TestResult {
   constructor(public succeeded: boolean, public error: string, public issueType: string, public issueDescription: string) {

   }
}

export class ErrorTestResult extends TestResult {
   constructor(public error: string, public issueType: string, public issueDescription: string) {
      super(false, error, issueType, issueDescription);
   }
}

export class SuccessTestResult extends TestResult {
   /**
    *
    */
   constructor() {
      super(true, null, null, null);
      
   }
}

export const FAILED: TestResult = new ErrorTestResult('Test failed', 'Unknown', 'An unknown issue occurred');

export const SUCCEEDED: TestResult = new SuccessTestResult();