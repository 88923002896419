












import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Component({})
export default class CustomConfirm extends Vue {
  @Prop() title: string;
  @Prop() body: string;
  public shown: boolean = false;
  showPromiseResolver: (value: boolean) => void;

  public async show() {
    this.shown = true;
    return new Promise(resolve => {
        this.showPromiseResolver = resolve;
    });
  }

  clickMe(value: boolean) {
      this.shown = false;
      this.showPromiseResolver(value);
  }
}
