import fetchJson from "@/zero/utilities/fetch-json";

export default class AgoraRecordingApi {
   baseUrl: string;

   constructor() {
      this.baseUrl = process.env.VUE_APP_AGORA_RECORDINGS_APIS_BASEURL || 'https://localhost:5005/api/AgoraRecordings';

   }

   async startRecording(options: StartAgoraRecordingOptions): Promise<AgoraRecordingInfo> {
      const { channelName, recordingSession } = options;
      try {
         const recordingInfo = await fetchJson<AgoraRecordingInfo>(`${this.baseUrl}`, {
            method: 'POST',
            body: JSON.stringify({
               ...options,
               recordingSession: recordingSession.replace(/-/g, ''),
            }),
            headers: {
               "content-type": "application/json"
            }
         });
         return recordingInfo;
      } catch (error) {
         console.error(`An error occurred trying to start agora recording: ${JSON.stringify(error)}`);
         return new AgoraRecordingInfo(channelName, null, null, null, error);
      }
   }

   async stopRecording(recordingInfo: AgoraRecordingInfo): Promise<AgoraStopResult> {
      try {
         const stopResult = await fetchJson<AgoraStopResult>(`${this.baseUrl}/stop`, {
            method: 'POST',
            body: JSON.stringify(recordingInfo),
            headers: {
               "content-type": "application/json"
            }
         });
         return stopResult;
      } catch (error) {
         console.log(`An error occurred trying to start agora recording: ${JSON.stringify(error)}`);
         return null;
      }
   }

}

export class AgoraRecordingInfo {
   constructor(public channelName, public sid: string, public resourceId: string, public uid: string, public error: any) { }
}

export class AgoraStopResult {
   resourceId: string;
   sid: string;
   serverResponse: AgoraServerResponse;


}

export class AgoraCloudFile {
   fileName: string;
   isPlayable: boolean;
   mixedAllUser: boolean;
   sliceStartTime: number;
   trackType: "audio" | "video";
   uid: string
}

export class AgoraServerResponse {
   fileListMode: string
   fileList: AgoraCloudFile[];
   uploadingStatus: string
}

export class StartAgoraRecordingOptions {
   channelName?: string;
   recordingSession?: string;
   uids?: string[];
   timestamp?: number;
   maxIdleTime?: number = 30;
}