









































































import BaseVue from "@/zero/utilities/base-vue";
import jwtDecode from "jwt-decode";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ControlPanel from "../components/ControlPanel.vue";
import { BrowserMultiFormatReader, Exception } from "@zxing/library";
import { PrepareRecordingSessionUploadRequest } from "@/zero/services/upload-service";
import * as Sentry from "@sentry/vue";
import { sleep, Stopwatch } from "@/zero/utilities/sleep";
import CustomConfirm from "../components/CustomConfirm.vue";
import { getCurrentAuthToken } from "@/zero/services/goose-apis";

@Component({
  components: {
    ControlPanel,
    CustomConfirm,
  },
})
export default class NFCReader extends BaseVue {
  @Prop() session: string;
  @Prop() booth: string;
  @Prop() mode: "Online" | "InStore";
  state: "loading" | "loaded" | "collectingEmail" | "preparingUpload" | "uploadPrepared" = "loading";
  recordingsFiles: string[] = [];
  baseUrl = process.env.VUE_APP_UPLOAD_API_URL || (/localhost/.test(location.href) ? "https://localhost:5001/api" : "https://gooseserverdev.eu.ngrok.io/api");
  nfcArray: string[] = [];
  code: string = "";
  clientEmail: string = "";
  checkPrivacy1: boolean = false;
  checkPrivacy2: boolean = false;
  locale: string = this.$route.params.locale || "en";
  detectInterval: number;
  stream: MediaStream;
  codeReader = new BrowserMultiFormatReader();
  prepareError: string = null;
  startedAt: any;
  endedAt: any;
  nfcInvalid = false;
  nfcAlreadyExist = false;

  constructor() {
    super();
  }

  public get askEmail(): boolean {
    return true;
    return this.locale === "en";
  }

  async mounted() {
    console.log("Mode is " + this.mode);
    if (process.env.VUE_APP_FULL_SESSION_RECORDING_ENABLED == "true" || process.env.VUE_APP_THREE_SECONDS_VIDEO_GADGET_ENABLED == "true") {
      const recordingsFilesSerialized = localStorage.getItem(`recordingFiles_${this.session}`);
      console.log("Read recording files serializer " + recordingsFilesSerialized);
      if (recordingsFilesSerialized != "undefined") {
        this.recordingsFiles = JSON.parse(recordingsFilesSerialized);
      }
      const { startedAt, endedAt } = await this.$dbService.getSessionTimings(this.session);

      this.startedAt = startedAt || new Date();
      this.endedAt = endedAt || new Date();
    }
    this.stream = await navigator.mediaDevices.getUserMedia({
      video: {
        width: { min: 1278, ideal: 1278 },
        height: { min: 720, ideal: 720 },
        frameRate: { min: 15, ideal: 15 },
        aspectRatio: { ideal: 1.7777778 },
      },
    });
    const theVideo = this.$refs.video as HTMLVideoElement;
    theVideo.srcObject = this.stream;

    await new Promise<void>((resolve) => {
      theVideo.oncanplay = () => resolve();
    });

    await this.codeReader.decodeFromStream(this.stream, this.$refs.video as HTMLVideoElement, (result: any, err) => {
      if (result && result.text) {
        if (!/^[A-Z0-9]+$/.test(result.text)) {
          this.nfcInvalid = true;
          return;
        }

        if (this.nfcArray.indexOf(result.text) >= 0) {
          this.nfcAlreadyExist = true;
          return;
        }

        this.code = result.text;
        this.nfcArray.push(this.code);
        this.stopNFC();
      }
    });

    await this.joinBoothControl(1111, this.booth, "seller");

    this.state = "loaded";
  }

  skipNFC() {
    this.codeReader.stopContinuousDecode();
    this.state = "collectingEmail";
  }

  async stopNFC() {
    this.code = "";
    if (await (this.$refs.nfcAlert as CustomConfirm).show()) {
      this.nfcAlreadyExist = false;
      this.nfcInvalid = false;
      return;
    }

    this.codeReader.stopContinuousDecode();
    this.state = "collectingEmail";
  }

  get showAlertExist() {
    return this.nfcAlreadyExist && !(this.$refs.nfcAlert as CustomConfirm)?.shown;
  }

  get showAlertInvalid() {
    return this.nfcInvalid && !(this.$refs.nfcAlert as CustomConfirm)?.shown;
  }

  async prepareSessionUpload(doCheckValidity: boolean): Promise<boolean> {
    const theForm = this.$refs.mailForm as HTMLFormElement;
    if (!theForm.checkValidity()) {
      theForm.reportValidity();
      return false;
    }
    this.state = "preparingUpload";
    let allOk = false;
    try {
      let doApi = true,
        doUpload = true,
        doMerge = true;
      try {
        const sw = new Stopwatch();
        while (this.state == "preparingUpload" && !sw.elapsedMinutes(2) && (doApi || doUpload || doMerge)) {
          const { apiOk, uploadOk, mergeOk } = await this.callBackendPrepares(doApi, doUpload, doMerge);
          allOk = apiOk && uploadOk && mergeOk;
          doApi = !apiOk;
          doUpload = !uploadOk;
          doMerge = !mergeOk;
          if (doApi || doUpload || doMerge) {
            await sleep(5000);
            continue;
          }
        }
        if (doApi || doUpload || doMerge) throw "Can't prepare session in 2 minutes time, giving up";
      } catch (error) {
        console.error(error);
        this.prepareError = "There was an error, please retry or contact support";
        return false;
      }

      localStorage.removeItem(`recordingFiles_${this.session}`);
      return allOk;
    } catch (error) {
      this.prepareError = `There was an error, please retry or contact support (Error details: ${error})`;
      console.log("error");
    } finally {
      if (allOk) {
        this.state = "uploadPrepared";
        window.setTimeout(
          () =>
            this.$router.push({
              name: "seller",
              params: {
                booth: this.booth,
                locale: this.locale,
              },
            }),
          5000
        );
      } else {
        this.state = "collectingEmail";
      }
    }
  }

  async callBackendPrepares(doApi: boolean = true, doUpload: boolean = true, doMerge: boolean = true): Promise<{ apiOk: boolean; uploadOk: boolean; mergeOk: boolean }> {
    const result: { apiOk: boolean; uploadOk: boolean; mergeOk: boolean } = {
      apiOk: !doApi,
      uploadOk: !doUpload,
      mergeOk: !doMerge,
    };

    const prepareUploadRequest = new PrepareRecordingSessionUploadRequest(
      this.session,
      this.recordingsFiles,
      this.booth,
      this.clientEmail,
      this.nfcArray, //
      this.startedAt,
      this.endedAt,
      this.$gooseapi.getBoothLocale() || "en",
      this.mode
    );
    result.uploadOk = await this.$uploadApi.prepareRecordingSessionUpload(prepareUploadRequest).catch((error) => {
      console.error(error);
      Sentry.captureException(error);
      return false;
    });
    if (!result.uploadOk) return result;

    result.mergeOk = await this.sendMergeFiles();
    if (!result.mergeOk) return result;
    result.apiOk = await this.$gooseapi.prepareRecordingSessionUpload(this.session, this.recordingsFiles, this.booth, this.clientEmail).catch((error) => {
      console.error(error);
      Sentry.captureException(error);
      return false;
    });
    return result;
  }

  async sendMergeFiles(): Promise<boolean> {
    try {
      const form = new FormData();
      form.append("session", this.session);
      form.append("email", this.clientEmail);
      form.append("experienceType", "SneakerMakerOffline");
      let driver = "default";
      if (process.env.VUE_APP_THREE_SECONDS_VIDEO_GADGET_ENABLED == "true") driver = "HeadAndTail";
      form.append("driver", driver);
      const response = await fetch(`${this.baseUrl}/merge-session-files`, {
        method: "POST",
        body: form,
      }).then(async (response) => {
        if (!response.ok) {
          console.error(`Error sending merge session file request ${response.status} - ${response.statusText}`);
          return response;
        }

        return this.askEmail ? await this.enqueueEmail() : await this.enqueueSMS();
      });

      console.log("merge: ", response.ok);

      return response.ok;
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      return false;
    }
  }

  async enqueueSMS(): Promise<any> {
    return await fetch(`${this.baseUrl}/enqueue-download-sms`, {
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        session: this.session,
        phoneNumber: this.clientEmail,
        host: `${location.protocol}//${location.host}/${this.locale}/get-your-experience/${this.session}`,
        experienceType: "SneakerMakerOffline",
        locale: this.locale,
      }),
      method: "post",
    }).catch((reason) => {
      return new Response(null, { status: 500, statusText: reason });
    });
  }

  async enqueueEmail(): Promise<Response> {
    const token = getCurrentAuthToken();
    if (!token) return new Response(null, { status: 500, statusText: "Invalid auth token saved locally" });
    const jwt = jwtDecode(token);
    const boothLocation = jwt["booth-location-description"];
    const booth = jwt["booth"];
    return await fetch(`${this.baseUrl}/enqueue-download-email`, {
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        session: this.session,
        email: this.clientEmail,
        host: `${location.protocol}//${location.host}/${this.locale}/get-your-experience/${this.session}`,
        experienceType: "SneakerMakerOffline",
        locale: this.locale,
        boothLocation,
        booth,
      }),
      method: "post",
    }).catch((reason) => {
      return new Response(null, { status: 500, statusText: reason });
    });
  }

  paintBoundingBox(detectedCodes, ctx) {
    for (const detectedCode of detectedCodes) {
      const {
        boundingBox: { x, y, width, height },
      } = detectedCode;

      ctx.lineWidth = 2;
      ctx.strokeStyle = "#007bff";
      ctx.strokeRect(x, y, width, height);
    }
  }

  async beforeDestroy() {
    this.stream.getTracks().forEach((t) => {
      try {
        t.stop();
      } catch (error) {
        // do nothing
      }
    });
  }
}
