























import AgoraTest from "@/views/CheckDevice/agora-test";
import CameraTest from "@/views/CheckDevice/camera-test";
import { IDeviceTest } from "@/views/CheckDevice/IDeviceTest";
import MicTest from "@/views/CheckDevice/mic-test";
import { TestResult } from "@/views/CheckDevice/TestResult";
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
@Component({})
export default class BuyerDeviceChecks extends Vue {
  @Prop({ default: false }) allowConsoleDownload: boolean;
  tests: IDeviceTest[] = [new MicTest("Microphone", this), new CameraTest("Camera", this), new AgoraTest("Connectivity", this)];

  // Simulate failure, set value after && to true to simulate failure in dev environment
  simulateFailure = process.env.VUE_APP_ENV == "development" && false;
  // Simulate slow test execution, set value after && to a number greater that 0 to simulate slow tests
  simulatedSlowiness: number = process.env.VUE_APP_ENV == "development" && 0;
  suggestions: string[] = [];
  consoleSetUp: boolean;
  errorTitle: string = null;

  public get hasFailures(): boolean {
    return this.tests.some(t => !t.succeeded && t.status === "finished");
  }

  testItemClass(test: IDeviceTest) {
    const result = {};
    result["test-item--" + test.name.toLowerCase()] = true;
    if (test.status === "finished") result["test-item--" + (test.succeeded ? "ok" : "ko").toLowerCase()] = test.status === "finished";
    result["test-item--" + test.status] = true;
    return result;
  }

  async mounted() {
    if (this.allowConsoleDownload) {
      this.setupConsole();
    }
    await this.doChecks();
  }

  async doChecks() {
    this.$root.$emit("buyer-device-checks-start");
    this.tests.forEach(t => t.clear());
    let testResult: TestResult;
    const start = new Date();
    for (var t of this.tests) {
      testResult = await t.run(true, this.simulateFailure, this.simulatedSlowiness);
      if (!testResult || !testResult.succeeded) {
        this.suggestions = t.suggestions;
        this.errorTitle = t.failureTitle;
        break;
      }
    }
    const duration = Number(new Date()) - Number(start);
    if (this.hasFailures) {
      this.$root.$emit("buyer-device-checks-completed-ko");
    } else {
      this.$root.$emit("buyer-device-checks-completed-ok");
    }
    try {
      this.$gooseapi.reportDeviceIssue(testResult.issueType, testResult.issueDescription, this.hasFailures ? "KO" : "OK", duration);
    } catch (error) {
      console.warn(`Failed to report device test results: ${error}`);
    }
  }

  saveLogs() {
    const text = console.logs.map(l => `${l.level.padEnd(4, "\t")}\t${l.data.map(d => d.toString())}`).join("\n");
    const blob = new Blob([text], { type: "text/plain" });
    var downloadLink = document.createElement("a");
    downloadLink.download = "console-" + Number(new Date()) + ".log";
    downloadLink.href = window.webkitURL.createObjectURL(blob);
    downloadLink.click();
  }

  setupConsole() {
    if (this.consoleSetUp) return;
    window.console;
    console.defaultLog = console.log.bind(console);
    console.logs = [];
    console.log = function() {
      // default &  console.log()
      console.defaultLog.apply(console, arguments);
      // new & array data
      console.logs.push({ level: "log", data: Array.from(arguments) });
    };
    console.defaultError = console.error.bind(console);
    console.logs = [];
    console.error = function() {
      // default &  console.error()
      console.defaultError.apply(console, arguments);
      // new & array data
      console.logs.push({ level: "error", data: Array.from(arguments) });
    };
    console.defaultWarn = console.warn.bind(console);
    console.logs = [];
    console.warn = function() {
      // default &  console.warn()
      console.defaultWarn.apply(console, arguments);
      // new & array data
      console.logs.push({ level: "warn", data: Array.from(arguments) });
    };
    console.defaultDebug = console.debug.bind(console);
    console.logs = [];
    console.debug = function() {
      // default &  console.debug()
      console.defaultDebug.apply(console, arguments);
      // new & array data
      console.logs.push({ level: "debug", data: Array.from(arguments) });
    };
    this.consoleSetUp = true;
  }

  beforeDestroy() {
    if (this.allowConsoleDownload) {
      console.debug = console.defaultDebug;
      console.error = console.defaultError;
      console.log = console.defaultLog;
      console.warn = console.defaultWarn;
      this.consoleSetUp = false;
    }
  }
}
declare global {
  interface Console {
    defaultLog: (...data: any[]) => void;
    defaultWarn: (...data: any[]) => void;
    defaultError: (...data: any[]) => void;
    defaultDebug: (...data: any[]) => void;
    logs: { level: "log" | "debug" | "warn" | "error"; data: any[] }[];
  }
}
